import React from "react";

import "./privacy.css";
import "./privacyMobile.css";

const Privacy = () => {
  return (
    <div>
      {/* hero section start */}
      <section className="heroSection4">
        <div className="container">
          <div className="heroArea4">
            <div className="heroTxts4">
              <h1
                className="heroTitle4"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-once="false"
              >
                Privacy Policy
              </h1>
              <p
                className="hero4para"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-once="false"
              >
                NEED SUPPORT:{" "}
                <a href="mailto:support@egoxify.com" className="egrEmailLink">
                  support@egoxify.com
                </a>
              </p>
            </div>

            <div className="hero-images4" data-aos="fade-left">
              <img src="/img/privacy-image.svg" alt="" className="privacy" />
            </div>
          </div>
          <div className="privacyTextList">
            <span
              className="privacyTextList_span
            "
            >
              Last Date Updated 15 December 2023.
            </span>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  1. <span className="titleName">About this Notice</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Egoxify adheres to the stipulations of data protection laws and
                treats personal information with utmost confidentiality.
                Disclosure of personal data to third parties occurs only within
                the bounds allowed by data protection regulations or with
                explicit consent from the individual.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  2.{" "}
                  <span className="titleName">
                    The Data We Collect About You?
                  </span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                This Data Privacy Policy elucidates the procedures for
                processing personal data when utilizing this website. Personal
                data, as per Article 4(1) of the EU General Data Protection
                Regulation (“GDPR”), encompasses identifiable information about
                a natural person, such as name, email address, postal address,
                or telephone number. Excluded from this definition are details
                that cannot be directly linked to an individual's identity, such
                as the number of visitors to the website.
                <br />
                <br /> For inquiries, suggestions, or comments regarding data
                privacy, please contact our data protection officer via email at
                <a href="mailto:support@egoxify.com">support@egoxify.com.</a>
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle " data-aos="fade-right">
                  3.{" "}
                  <span className="titleName">
                    Processed Personal Data includes:
                  </span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Contact details (e.g., name, email, phone number)
                <br /> Usage data (e.g., visited websites, clicked links, viewed
                content)
                <br />
                Communication data (e.g., device IDs, IP addresses)
                <br /> Affected Persons are referred to as Users, encompassing
                visitors to the website.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  4.{" "}
                  <span className="titleName">
                    We process personal data for the following purposes:
                  </span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Provisioning of the website, its functions, and contents
                Responding to contact requests and communicating with users
                Measuring the usage of the website Implementing safety measures
                Conducting marketing activities Third-party Services and
                Cross-Border Data Processing involve disclosing data only as
                permitted by regulations. Data processing outside the EU or EFTA
                is conducted in compliance with data protection regulations,
                with user consent or recognized data protection standards like
                the “Privacy Shield” for the US.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  5. <span className="titleName">Users have rights to:</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Confirm if their data is being processed and obtain a copy
                Request completion or correction of incomplete or incorrect data
                Request deletion of their data Revoke consent for future data
                processing Take legal action or appeal to supervisory
                authorities if they believe their rights have been infringed due
                to non-compliance with data protection regulations. Cookies are
                utilized to enhance website user-friendliness and effectiveness.
                By using our website and agreeing to this policy, users consent
                to cookie usage. However, users can manage cookies through
                browser functions or our website's cookie settings, though this
                may impact website functionality.
                <br />
                <br />
                Deletion of Data occurs when it is no longer necessary,
                respecting statutory storage requirements. Comments and posts
                may store IP addresses or device identifiers for security
                purposes, alongside the corresponding content, unless the user
                dissents.
              </p>
            </div>
          </div>
        </div>

        <img src="/img/projects-blurbg.png" alt="" className="privacyBlurBg" />
        <img
          src="/img/privacy-rightbg.png"
          alt=""
          className="privacyrightBlurBg"
        />
      </section>
      {/* hero section end */}
      {/* =================================================================================================================================================================================================================================================================== */}
    </div>
  );
};

export default Privacy;
