import React, { useState, useEffect, useRef, useContext } from "react";
// import { createBlockies } from "ethereum-blockies";
import { API_URL } from "./actions/types";
import axios from "axios";
import { format, fromUnixTime } from "date-fns";
import { Connect } from "react-redux";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import "./UpdatedAppPagesStyles/StakingUpdate.css";
import rewardIcon from "../../../../LottieFiles/loadingIcon/FillupJar.json";
import Lottie from "lottie-react";
import Blockies from "react-blockies";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Nodata from "../nodataComponent/Nodata";
import { config } from "./actions/Config";
import { parseEther, formatEther } from "@ethersproject/units";
import ScaleLoader from "react-spinners/ScaleLoader";
import UpdatedSuccessModal from "./UpdatedSuccessErrorModals/UpdatedSuccessModal";
import UpdatedErrorModal from "./UpdatedSuccessErrorModals/UpdatedErrorModal";
import UpdatedWarningModal from "./UpdatedSuccessErrorModals/UpdatedWarningModal";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import ErrorModal from "../../Cex/components/ErrorModal/ErrorModal";
import SuccessModal from "../../Cex/components/SuccessModal/SuccessModal";
import Timer from "./Timer";
import Web3 from "web3";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";

import {
  annually,
  monthly,
  tokenBalance,
  takeRoyalty,
  checkAllowanceV3,
  unlockTokenV3,
  getEGCEUSDTICKERPRICE,
  getRoyaltyStats,
  stakeConfig,
  getCalculatedRoyalty,
  UnlockLockedStake,
} from "../../../../web3/index";

import { getDate, getMonth } from "date-fns";

import {
  POPULATE_STAKE_INFO,
  POPULATE_STAKE_GENERAL_INFO,
} from "./services/stakeServices";
import { socket } from "../socket";
import { numberWithCommas } from "../../../static";

export const DurationDiv = ({ addMonthly, addYearly, SelectedDuration }) => {
  return (
    <div className="duration_dropDown_div">
      <div
        className={
          SelectedDuration === "monthly"
            ? "duration_dropDown_div_cont1_selected"
            : "duration_dropDown_div_cont1"
        }
        onClick={addMonthly}
      >
        Monthly (1 month)
      </div>

      <div
        className={
          SelectedDuration === "yearly"
            ? "duration_dropDown_div_cont1_selected"
            : "duration_dropDown_div_cont1"
        }
        onClick={addYearly}
      >
        Annually (1 year)
      </div>
    </div>
  );
};

const { REACT_APP_EGC_ADDRESS, REACT_APP_EUSD_ADDRESS } = process.env;

const Stake = () => {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;
  const [durationDrop, setDurationDrop] = useState(false);
  const [Duration, setDuration] = useState(null);
  const [SelectedDuration, setSelectedDuration] = useState("");
  const [estimatedRewardDiv, setEstimatedRewardDiv] = useState(false);
  const [baseBalance, setBaseBalance] = useState(0.0);
  const [coinBalance2, setCoinBalance2] = React.useState(0.0);
  const [base, setBase] = useState("");
  const [asset, setAsset] = useState("");
  const [lockAmount, setLockAmount] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [lockDate, setLockDate] = useState(null);
  const [activeTab, setActiveTab] = useState("lock");
  const [estimatedRewardAmnt, setEstimatedRewardAmnt] = useState(0);
  const [tokenBal, setTokenBal] = useState(0.0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [Disable, setDisable] = useState(false);
  const [ClaimDisable, setClaimDisable] = useState(true);
  const [lockDisable, setLockDisable] = useState(false);
  const [LockedTransactions, setLockedTransactions] = useState([]);
  const [UniqueLockedTransactions, setUniqueLockedTransactions] = useState([]);
  const [egcUsdVal, setEgcUsdVal] = useState(0);
  const [graphData2, setGraphData2] = useState([]);
  const [availableClaimReward, setAvailableClaimReward] = useState("0.00");
  const [nextRewardTakeTime, setNextRewardTakeTime] = useState("");
  const [unlockStakeTime, setUnlockStakeTime] = useState("");
  const [unlockStakeDuration, setUnlockStakeDuration] = useState("");
  const [TotalClaimedReward, setTotalClaimedReward] = useState("0.00");
  const [dailyReward, setDailyReward] = useState("0.00");
  const [myTotalStaked, setMyTotalStaked] = useState("0.00");
  const [rewardCountDown, setRewardCountDown] = useState(false);
  const [notDueButton, setNotDueButton] = useState(false);
  const [notDueDiv, setNotDueDiv] = useState(false);

  const [myAssetInfo, setMyAssetInfo] = useState({});
  const [totalAssetInfo, setTotalAssetInfo] = useState({
    amount: 0,
    dailyRoyalty: 0,
  });
  const [txHash, setTxHash] = useState("");
  const [unlockBtn, setUnlockBtn] = useState(true);
  const [unLockCheckStatus, setUnLockCheckStatus] = useState(false);
  const toggleDurationDrop = () => {
    setDurationDrop(!durationDrop);
  };

  const addMonthly = () => {
    const currentDate = new Date();
    // get current date
    currentDate.setMonth(currentDate.getMonth() + 1); // add one month
    const month = currentDate.getMonth() + 1; // get the month (0-based index)
    const day = currentDate.getDate(); // get the day of the month
    const year = currentDate.getFullYear().toString().slice(-2); // get the year (last two digits)

    // pad month and day with a leading zero if necessary
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");

    // create the formatted date string
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    // setLockDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
    const dateString = currentDate;
    const date = new Date(dateString);

    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const formattedDateString = date.toLocaleDateString("en-US", options);
    setLockDate(formattedDateString);
    console.log(formattedDate); // Output: Mon Mar 20 2023
    console.log(formattedDateString); // output: e.g. "03/20/23" for current date of Feb 20, 2023
    console.log(currentDate); // output: e.g. "03/20/23" for current date of Feb 20, 2023
    setDuration(formattedDate);
    setDurationDrop(!durationDrop);
    setSelectedDuration("monthly");
  };

  const addYearly = () => {
    const currentDate = new Date(); // get current date
    currentDate.setFullYear(currentDate.getFullYear() + 1); // add one year
    const month = currentDate.getMonth() + 1; // get the month (0-based index)
    const day = currentDate.getDate(); // get the day of the month
    const year = currentDate.getFullYear().toString().slice(-2); // get the year (last two digits)

    // pad month and day with a leading zero if necessary
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");

    // create the formatted date string
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    // setLockDate(
    //   new Date(currentDate.setFullYear(currentDate.getFullYear() + 1))
    // );
    const dateString = currentDate;
    const date = new Date(dateString);

    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const formattedDateString = date.toLocaleDateString("en-US", options);
    setLockDate(formattedDateString);
    console.log(formattedDate);
    setDuration(formattedDate);
    setDurationDrop(!durationDrop);
    setSelectedDuration("yearly");
  };

  const AmountChange = (e) => {
    setLockAmount(e.target.value);
    console.log(e.target.value);
    if (e.target.value < 1) {
      setEstimatedRewardDiv(false);
    } else {
      setEstimatedRewardDiv(true);
    }
    setEstimatedRewardAmnt(e.target.value * 0.0012);
  };

  const StakeMonthly = async () => {
    setIsLoading(true);
    setDisable(true);
    const res = await monthly(
      parseEther(
        lockAmount === "" ? "0".toString() : lockAmount.toString(),
        "wei"
      ).toString(),
      library.getSigner()
    );
    console.log(res, "somto8uhhhg");
    console.log(res.status, "somto8uhhhg");
    if (res.status === true) {
      setIsLoading(false);
      setDisable(false);
      setSuccessModal(true);
      setSuccessMessage(
        "You've successfully Locked" + lockAmount + " eusd for 1 month"
      );
      setTxHash(res.message.hash);
    } else {
      console.log(res);
      console.log(res.message);
      setIsLoading(false);
      setDisable(false);
      setErrorModal(true);
      setErrorMessage(res.message);
    }
  };

  const StakeYearly = async () => {
    setIsLoading(true);
    setDisable(true);
    const res = await annually(
      parseEther(
        lockAmount === "" ? "0".toString() : lockAmount.toString(),
        "wei"
      ).toString(),
      library.getSigner()
    );
    console.log(res, "somto8uhhhg");
    console.log(res.status, "somto8uhhhg");
    if (res.status === true) {
      setIsLoading(false);
      setDisable(false);
      setSuccessModal(true);
      setTxHash(res.message.hash);
      setSuccessMessage(
        "You've successfully Locked" + lockAmount + " eusd for 1 year"
      );
    } else {
      console.log(res);
      console.log(res.message);
      setIsLoading(false);
      setDisable(false);
      setErrorModal(true);
      setErrorMessage(res.message);
    }
  };

  const UnlockStake = async () => {
    setIsLoading2(true);
    setLockDisable(true);
    setNotDueDiv(!notDueDiv);
    const res = await UnlockLockedStake(library.getSigner());
    console.log(res, "somto8uhhhg");
    console.log(res.status, "somto8uhhhg");
    if (res.status === true) {
      setIsLoading2(false);
      setLockDisable(false);
      setSuccessModal(true);
      setTxHash(res.message.hash);
      setSuccessMessage(
        "You've successfully unlocked " +
          parseFloat(myTotalStaked).toFixed(2) +
          " eusd"
      );
    } else {
      console.log(res);
      console.log(res.message);
      setIsLoading2(false);
      setLockDisable(false);
      setErrorModal(true);
      setErrorMessage(res.message);
    }
  };

  const TakeReward = async () => {
    setIsLoading2(true);
    setClaimDisable(true);
    const res = await takeRoyalty(library.getSigner());
    console.log(res, "somto8uhhhg");
    console.log(res.status, "somto8uhhhg");
    if (res.status === true) {
      setIsLoading2(false);
      setClaimDisable(false);
      setSuccessModal(true);
      setTxHash(res.message.hash);
      setSuccessMessage(
        "You've successfully claimed " +
          parseFloat(availableClaimReward).toFixed(2) +
          " eusd"
      );
    } else {
      console.log(res);
      console.log(res.message);
      setIsLoading2(false);
      setClaimDisable(false);
      setErrorModal(true);
      setErrorMessage(res.message);
    }
  };

  const toggleLockTabs = (e) => {
    let target = e.currentTarget.id;
    setActiveTab(target);
  };

  const CloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const CloseErrorModal = () => {
    setErrorModal(false);
  };

  const getStakeData = async () => {
    await axios
      .get(API_URL + "/stable/staking/all", null, config)
      .then((data) => {
        console.log(data);
        console.log(data.data.data);
        setLockedTransactions(data.data.data);
        const temp = data.data.data;
        for (const data of temp) {
          data.amount = Number(parseInt(data.amount).toFixed(2));
          // Input date string
          const inputDateString = data.time;

          // Create a Date object from the input string
          const date = new Date(inputDateString);

          // Define months array for conversion
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          // Extract date components
          const day = date.getUTCDate();
          const month = months[date.getUTCMonth()];
          const year = date.getUTCFullYear();
          let hours = date.getUTCHours();
          const minutes = date.getUTCMinutes();
          const ampm = hours >= 12 ? "pm" : "am";

          // Convert hours to 12-hour format
          hours = hours % 12;
          hours = hours ? hours : 12; // Set 12 for midnight

          // Create the formatted date string
          const formattedDateString = `${day} ${month} ${year} ${hours}:${minutes}${ampm}`;

          console.log(formattedDateString);
          data.time = formattedDateString;
        }
        setGraphData2(() => temp);
        const array = temp.map((data) => {
          return parseInt(data.amount);
        });
        console.log(array, "higi");
      })
      .catch((error) => {
        console.log(error.response);
      });

    socket.connect();
    socket.on("staking", (stakings) => {
      // alert(JSON.stringify(stakings));
    });
  };
  useEffect(() => {
    getStakeData();
  }, []);

  const getUserStakeData = async () => {
    await axios
      .get(API_URL + "/stable/staking/user/" + account, null, config)
      .then((data) => {
        console.log(data);
        console.log(data.data.data);
        setUniqueLockedTransactions(data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    if (account) {
      getUserStakeData();
    }
  }, [account]);

  const getTokenBalance = async () => {
    let res = await tokenBalance(
      REACT_APP_EUSD_ADDRESS,
      account,
      library.getSigner()
    );
    console.log(res);
    console.log(formatEther(res.message._hex));
    setCoinBalance2(parseFloat(formatEther(res.message._hex)).toFixed(2));
  };

  useEffect(() => {
    if (account) {
      getTokenBalance();
    }
  }, [account]);

  const maxAmount = () => {
    setLockAmount(coinBalance2);
    setEstimatedRewardAmnt(coinBalance2 * 0.0012);
    setEstimatedRewardDiv(true);
  };

  const fetchData2 = async () => {
    const response = await POPULATE_STAKE_INFO(account);
    if (response.success === true) {
      console.log(response.data.user, "oyibo");
      setMyAssetInfo(response.data.user);
    }
  };

  useEffect(() => {
    if (account) {
      fetchData2();
    }
  }, [account]);

  const getRewardStats = async () => {
    const res = await getRoyaltyStats(account, library.getSigner());
    console.log(res);
    console.log(res.message._dailyRoyalty.toString());
    console.log(formatEther(res.message._dailyRoyalty).toString());
    console.log(formatEther(res.message._totalRoyaltyTaken).toString());
    setDailyReward(formatEther(res.message._dailyRoyalty).toString());
    setTotalClaimedReward(
      formatEther(res.message._totalRoyaltyTaken).toString()
    );
    setMyTotalStaked(formatEther(res.message._totalStake).toString());
    let formatted = res.message._nextRoyaltyTakePeriod.toString();
    let formatted2 = res.message._lockPeriod.toString();
    const endDate2 = formatted2;

    const endDate = formatted;
    const newRewardDate = new Date(endDate * 1000);
    const newRewardDate2 = new Date(endDate2 * 1000);
    console.log(newRewardDate2);
    console.log(newRewardDate);
    const date = newRewardDate2;
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    console.log(formattedDate);
    setUnlockStakeDuration(formattedDate);
    setNextRewardTakeTime(newRewardDate);
    setUnlockStakeTime(newRewardDate2);
    if (newRewardDate <= new Date() && myTotalStaked !== "0.0") {
      setClaimDisable(false);
      setRewardCountDown(false);
    } else {
      setClaimDisable(true);
      setRewardCountDown(true);
    }
    if (myTotalStaked === "0.0") {
      // setClaimDisable(true);
      setLockDisable(true);
    } else {
      // setClaimDisable(false);
      setLockDisable(false);
    }
  };
  useEffect(() => {
    if (account) {
      getRewardStats();
    }
    console.log(myTotalStaked);
  }, [account, myTotalStaked]);

  const getStakeConfig = async () => {
    const res = await stakeConfig(library.getSigner());
    console.log(res);
    console.log(res.message._DAYS_IN_A_YEAR.toString());
    console.log(res.message._YEARLY_INTEREST_RATE.toString());
    console.log(res.message._yearlyInterest.toString());
  };

  useEffect(() => {
    if (account) {
      getStakeConfig();
    }
  }, [account]);

  const getCalculatedReward = async () => {
    const res = await getCalculatedRoyalty(account, library.getSigner());
    console.log("====================================");
    console.log(res);
    console.log("====================================");
    if (res.message !== null) {
      console.log(res.message);
      setAvailableClaimReward(formatEther(res.message).toString());
      return;
    }
  };

  useEffect(() => {
    if (account) {
      getCalculatedReward();
      return;
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      console.log(availableClaimReward);
      console.log(nextRewardTakeTime);
      if (availableClaimReward === "0.00" || nextRewardTakeTime === "") {
        setClaimDisable(true);
        console.log(availableClaimReward);
      }
    }
  }, [account, availableClaimReward, nextRewardTakeTime]);

  const fetchData = async () => {
    const response = await POPULATE_STAKE_GENERAL_INFO();
    if (response.success === true) {
      console.log(response.data.user, "oyibo");
      console.log(response.data);
      if (response.data.amount === null) {
        return;
      }
      setTotalAssetInfo({
        amount: response.data.amount,
        dailyRoyalty: response.data.totalRoyalty,
      });
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (unlockStakeTime !== new Date() || unlockStakeTime > new Date()) {
      console.log("its not yet due");
      setNotDueButton(true);
    }
  }, [unlockStakeTime]);

  const toggleNotDueDiv = () => {
    setNotDueDiv(!notDueDiv);
  };

  const UnlockToken = async (e) => {
    setIsLoading(true);
    setDisable(true);
    console.log("====================================");
    console.log(REACT_APP_EUSD_ADDRESS);
    console.log("====================================");
    let ret = await unlockTokenV3(
      REACT_APP_EUSD_ADDRESS,
      parseEther("180000000000000000000000000000000000", "wei").toString(),
      library.getSigner()
    );
    console.log(ret);
    if (ret.status === true) {
      setIsLoading(false);
      setDisable(false);
      localStorage.setItem("unlocking", true);
      localStorage.setItem("unlockingHash", ret.message);
      setUnlockBtn(true);
    } else {
      if (ret.message.code === 4001) {
        console.log(ret);
      }
      console.log(ret);
      setErrorModal(true);
      setErrorMessage(ret.message);
      setIsLoading(false);
      setDisable(false);
    }
  };

  const CheckAllowance = async () => {
    let check = await checkAllowanceV3(
      REACT_APP_EUSD_ADDRESS,
      account,
      parseEther(
        lockAmount === "" ? "0".toString() : lockAmount.toString(),
        "wei"
      ).toString(),
      library.getSigner()
    );
    console.log(check);
    setUnLockCheckStatus(check.status);
    setUnlockBtn(check.status);
  };

  useEffect(() => {
    if (account) {
      CheckAllowance();
    }
  }, [account, unLockCheckStatus, unlockBtn, lockAmount]);

  return (
    <div className="earn_div">
      {/* get started section start */}
      {/* ============================================================ */}
      {/* ============================================================ */}
      {/* ============================================================ */}
      {/* ============================================================ */}
      {/* Tokens Section Start */}
      <section className="earn_section">
        <div className="earn_section_1">
          <div className="container">
            <div className="earn_section_1_area">
              <div className="earn_section_1_area_1">
                <div className="earn_section_1_area_1_cont1">
                  <img
                    src="/egox1.png"
                    alt=""
                    className="earn_section_1_area_1_cont-img"
                  />
                  Savings
                </div>
                <div className="earn_section_1_area_1_cont2">
                  eUSD holders have the ability to seamlessly lock and unlock
                  their tokens within the smart contract at any time. Once
                  locked, eUSD automatically accrues value based on the global
                  system variable known as the ESR (eUSD Stable Rate). This
                  dynamic reward system incentivizes long-term eUSD holdings and
                  contributes to the overall stability of the ecosystem.
                </div>
              </div>
              <div className="earn_section_1_area_2">
                <div className="earn_section_1_area_2_div1">
                  <div className="earn_section_1_area_2_div1_title">
                    Staked Asset
                  </div>
                  <div className="earn_section_1_area_2_div1_content">
                    {parseFloat(totalAssetInfo.amount).toFixed(2)} EUSD
                  </div>
                </div>
                <div className="earn_section_1_area_2_div1">
                  <div className="earn_section_1_area_2_div1_title">
                    Earned Rewards
                  </div>
                  <div className="earn_section_1_area_2_div1_content">
                    {parseFloat(totalAssetInfo.dailyRoyalty).toFixed(2)}EUSD
                  </div>
                </div>
                <div className="earn_section_1_area_2_div1">
                  <div className="earn_section_1_area_2_div1_title">
                    Staking APY
                  </div>
                  <div className="earn_section_1_area_2_div1_content">45%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="earn_section_2">
          <div className="container">
            <div className="earn_section_2_area1">
              <div className="earn_section_2_area1_cont1">
                <div className="earn_section_2_area1_cont1_title">
                  Your Info
                </div>
                <div className="earn_section_2_area1_cont1_asset_cont">
                  <div className="earn_section_2_area1_cont1_asset_cont_1_lottie_div">
                    <Lottie
                      animationData={rewardIcon}
                      loop={true}
                      autoPlay={true}
                      className="earn_section_2_area1_cont1_asset_cont_1_lottie_div_icon"
                      preserveAspectRatio="xMidYMid meet"
                    />
                    <div className="earn_section_2_area1_cont1_asset_cont_1_lottie_div_txt">
                      <div className="earn_section_2_area1_cont1_asset_cont_1_lottie_div_txt_title">
                        Earned Rewards
                      </div>
                      <div className="earn_section_2_area1_cont1_asset_cont_1_lottie_div_txt_content">
                        {parseFloat(TotalClaimedReward).toFixed(2)} eusd
                      </div>
                    </div>
                  </div>

                  <div className="earn_section_2_area1_cont1_asset_cont_1">
                    <div className="earn_section_2_area1_cont1_asset_cont_1_div">
                      <div className="earn_section_2_area1_cont1_asset_cont_1_title">
                        Staked Asset
                      </div>
                      <div className="earn_section_2_area1_cont1_asset_cont_1_token">
                        {parseFloat(myTotalStaked).toFixed(2)} EUSD
                      </div>
                    </div>
                    <div className="earn_section_2_area1_cont1_asset_cont_1_div">
                      <div className="earn_section_2_area1_cont1_asset_cont_1_title">
                        Your Asset
                      </div>
                      <div className="earn_section_2_area1_cont1_asset_cont_1_token">
                        {parseFloat(coinBalance2).toFixed(4)} EUSD
                      </div>
                    </div>
                  </div>
                  <div className="earn_section_2_area1_cont1_asset_cont_1b">
                    <div className="earn_section_2_area1_cont1_asset_cont_1_div">
                      <div className="earn_section_2_area1_cont1_asset_cont_1_title">
                        Daily Rewards
                      </div>
                      <div className="earn_section_2_area1_cont1_asset_cont_1_token">
                        {parseFloat(dailyReward).toFixed(4)} EUSD
                      </div>
                    </div>
                    {/* <div className="earn_section_2_area1_cont1_asset_cont_1_div">
                        <div className="earn_section_2_area1_cont1_asset_cont_1_title">
                          APY
                        </div>
                        <div className="earn_section_2_area1_cont1_asset_cont_1_token">
                          45%
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
              <div className="earn_section_2_area1_cont2">
                <div className="lock_container_cont1_div1_lock_div">
                  <div className="lock_container_cont1_div1_lock_div_tabs">
                    <div
                      id="lock"
                      className={
                        activeTab === "lock"
                          ? "lock_container_cont1_div1_lock_div_tab1_active"
                          : "lock_container_cont1_div1_lock_div_tab1"
                      }
                      onClick={toggleLockTabs}
                    >
                      Stake
                    </div>
                    <div
                      id="claim"
                      className={
                        activeTab === "claim"
                          ? "lock_container_cont1_div1_lock_div_tab1_active"
                          : "lock_container_cont1_div1_lock_div_tab1"
                      }
                      onClick={toggleLockTabs}
                    >
                      Claim
                    </div>
                    <div
                      id="unlock"
                      className={
                        activeTab === "unlock"
                          ? "lock_container_cont1_div1_lock_div_tab1_active"
                          : "lock_container_cont1_div1_lock_div_tab1"
                      }
                      onClick={toggleLockTabs}
                    >
                      Unstake
                    </div>
                  </div>
                </div>
                <div className="earn_section_2_area1_cont2_body">
                  {activeTab === "lock" ? (
                    <div className="lock_container_cont1_div1_lock_div_lock_body">
                      <div className="lock_container_cont1_div1_lock_div_lock_body_input_body">
                        <div className="lock_container_cont1_div1_lock_div_lock_body_input_body_head">
                          <span className="lock_container_cont1_div1_lock_div_lock_body_input_body_head_span1">
                            Deposit
                          </span>
                        </div>
                        <div className="lock_container_cont1_div1_lock_div_lock_body_input_body_cont">
                          <input
                            type="number"
                            value={lockAmount}
                            placeholder="0.00"
                            onChange={AmountChange}
                            className="lock_container_cont1_div1_lock_div_lock_body_input_body_input"
                          />
                          <div className="lock_container_cont1_div1_lock_div_lock_body_input_body_cont_amount_div">
                            <button
                              className="lock_container_cont1_div1_lock_div_lock_body_input_body_cont_amount_div1_btn"
                              onClick={maxAmount}
                            >
                              MAX
                            </button>
                            {/* <div className="lock_container_cont1_div1_lock_div_lock_body_input_body_cont_amount_div2">
                                egc
                              </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="lock_container_cont1_div1_lock_div_lock_body_input_body">
                        <div className="lock_container_cont1_div1_lock_div_lock_body_input_body_head">
                          <span className="lock_container_cont1_div1_lock_div_lock_body_input_body_head_span1">
                            Duration:{" "}
                            {SelectedDuration === "monthly" ? (
                              <> 1 month</>
                            ) : SelectedDuration === "yearly" ? (
                              <> 1 year</>
                            ) : null}
                          </span>
                          <span className="lock_container_cont1_div1_lock_div_lock_body_input_body_head_span2">
                            Expires: {lockDate}
                          </span>
                        </div>
                        <div className="lock_container_cont1_div1_lock_div_lock_body_input_body_cont">
                          <input
                            type="text"
                            value={Duration}
                            onClick={toggleDurationDrop}
                            placeholder="MM/DD/YY"
                            className="lock_container_cont1_div1_lock_div_lock_body_input_body_input"
                          />
                          <EventOutlinedIcon
                            onClick={toggleDurationDrop}
                            className="lock_container_cont1_div1_lock_div_lock_body_input_body_cont_icon"
                          />
                          {durationDrop ? (
                            <DurationDiv
                              addMonthly={addMonthly}
                              // addSemiMonthly={addSemiMonthly}
                              addYearly={addYearly}
                              SelectedDuration={SelectedDuration}
                            />
                          ) : null}
                        </div>
                      </div>
                      {estimatedRewardDiv ? (
                        <div className="estRewardDiv">
                          Estimated Daily Reward:{" "}
                          <span className="estRewardDiv_span">
                            {estimatedRewardAmnt} eUSD
                          </span>
                        </div>
                      ) : null}

                      {!account ? (
                        <>
                          {" "}
                          <button
                            disabled={true}
                            className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                          >
                            Connect wallet
                          </button>
                        </>
                      ) : (
                        <>
                          {unlockBtn === false ? (
                            <button
                              disabled={Disable}
                              onClick={UnlockToken}
                              className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                            >
                              {isLoading ? (
                                <ScaleLoader
                                  color="#da8166"
                                  size={10}
                                  height={20}
                                />
                              ) : (
                                <>Approve eusd</>
                              )}
                            </button>
                          ) : (
                            <>
                              {SelectedDuration === "monthly" &&
                              lockAmount != "" ? (
                                <button
                                  disabled={Disable}
                                  onClick={StakeMonthly}
                                  className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                                >
                                  {isLoading ? (
                                    <ScaleLoader
                                      color="#da8166"
                                      size={10}
                                      height={20}
                                    />
                                  ) : (
                                    <>Create Lock</>
                                  )}
                                </button>
                              ) : SelectedDuration === "yearly" &&
                                lockAmount != "" ? (
                                <button
                                  disabled={Disable}
                                  onClick={StakeYearly}
                                  className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                                >
                                  {isLoading ? (
                                    <ScaleLoader
                                      color="#da8166"
                                      size={10}
                                      height={20}
                                    />
                                  ) : (
                                    <>Create Lock</>
                                  )}
                                </button>
                              ) : lockAmount === "" ? (
                                <button
                                  disabled
                                  className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                                >
                                  Enter an amount
                                </button>
                              ) : (
                                <button
                                  disabled
                                  className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                                >
                                  Choose Duration
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : activeTab === "claim" ? (
                    <div className="lock_container_cont1_div1_lock_div_lock_body">
                      <div className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1">
                        <div className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_head">
                          Available Reward
                        </div>
                        <div className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_amount">
                          {parseFloat(availableClaimReward).toFixed(4)} eUsd
                          {rewardCountDown === true ? (
                            <div className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_amount_dollar_equiv">
                              Claim In the next:
                              <Timer deadline={nextRewardTakeTime} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                        onClick={TakeReward}
                        disabled={ClaimDisable}
                      >
                        {isLoading2 ? (
                          <ScaleLoader color="#da8166" size={10} height={20} />
                        ) : (
                          <>Claim Reward</>
                        )}
                      </button>
                    </div>
                  ) : activeTab === "unlock" ? (
                    <div className="lock_container_cont1_div1_lock_div_lock_body">
                      <div className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1">
                        <div className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_head">
                          My Locked eusd
                        </div>
                        <div className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_amount">
                          <span className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_amount_span">
                            <span className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_amount_span_span1">
                              {numberWithCommas(
                                parseFloat(myTotalStaked).toFixed(2)
                              )}{" "}
                              eusd
                            </span>
                            <span className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_amount_span_span2">
                              Max Duration:
                              <span className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_amount_span_span2_span">
                                ({unlockStakeDuration})
                              </span>
                            </span>
                          </span>
                          {rewardCountDown === true ? (
                            <div className="lock_container_cont1_div1_lock_div_lock_body_claim_Div1_amount_dollar_equiv">
                              Unlock In the next:
                              <Timer deadline={unlockStakeTime} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {notDueButton ? (
                        <button
                          className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                          onClick={toggleNotDueDiv}
                          disabled={lockDisable}
                        >
                          {isLoading2 ? (
                            <ScaleLoader
                              color="#da8166"
                              size={10}
                              height={20}
                            />
                          ) : (
                            <>Remove Lock </>
                          )}
                        </button>
                      ) : (
                        <button
                          className="lock_container_cont1_div1_lock_div_lock_body_input_body_btn"
                          onClick={UnlockStake}
                          disabled={lockDisable}
                        >
                          {isLoading2 ? (
                            <ScaleLoader
                              color="#da8166"
                              size={10}
                              height={20}
                            />
                          ) : (
                            <>Remove Lock </>
                          )}
                        </button>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="earn_section_3_area">
              <div className="earn_section_3_area_title">Transactions</div>
              <div className="earn_section_3_area_body">
                <table className="stakingTable_table">
                  <thead className="stakingTable_titles">
                    <tr className="stakingTable_title_div">
                      <th className="stakingTable_heading_titles stakingTable_heading_titles_first">
                        Action
                      </th>
                      <th className="stakingTable_heading_titles">Amount</th>
                      <th className="stakingTable_heading_titles">Address</th>

                      <th className="stakingTable_heading_titles stakingTable_heading_titles_last">
                        Txn Hash
                      </th>
                    </tr>
                  </thead>
                  {LockedTransactions.length <= 0 ? (
                    <div className="no_loans_div">
                      <div className="no_loans_div_cont">
                        <Nodata />
                        No Transaction Yet.
                      </div>{" "}
                    </div>
                  ) : (
                    <tbody
                      className="stakingTable_body"
                      id="popular-categories"
                    >
                      {/* =============== */}
                      {/* =============== */}
                      {/* =============== */}
                      {LockedTransactions.slice()
                        .reverse()
                        .map((data) => {
                          return (
                            <tr className="stakingTable_body_row ">
                              <td className="stakingTable_body_row_data stakingTable_body_row_data_first  ">
                                <div className="value_dolls_div">
                                  {data.status === "STAKE"
                                    ? "Create Lock"
                                    : data.status === "UNSTAKE"
                                    ? "Unlock"
                                    : null}

                                  <div className="value_dolls_div_val">
                                    {/* {formattedDate} */}
                                    {data.time}
                                  </div>
                                </div>
                              </td>
                              <td className="stakingTable_body_row_data">
                                <div className="value_dolls_div2">
                                  {data.status === "STAKE" ? (
                                    <span style={{ display: "flex" }}>
                                      {numberWithCommas(
                                        parseFloat(data.amount).toFixed(2)
                                      )}{" "}
                                      eusd
                                    </span>
                                  ) : data.status === "UNSTAKE" ? (
                                    <span style={{ display: "flex" }}>
                                      {numberWithCommas(
                                        parseFloat(data.unstake_amount).toFixed(
                                          2
                                        )
                                      )}{" "}
                                      eusd
                                    </span>
                                  ) : null}
                                </div>
                              </td>
                              <td className="stakingTable_body_row_data">
                                <div className="stakingTable_body_row_data_blockies_">
                                  <Blockies
                                    seed={data.user}
                                    size={8}
                                    scale={4}
                                    className="blockies_icon"
                                  />
                                  {`${data.user.slice(
                                    0,
                                    6
                                  )}...${data.user.slice(39, 42)}`}
                                </div>
                              </td>
                              <td className="stakingTable_body_row_data stakingTable_body_row_data_last">
                                <a
                                  href={`https://bscscan.com/tx/${data.tx}`}
                                  target="_blank"
                                >
                                  {" "}
                                  {`${data.tx.slice(0, 6)}...${data.tx.slice(
                                    63,
                                    66
                                  )}`}
                                  <OpenInNewIcon className="tx_hash_link_icon" />
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      {/* =================== */}
                      {/* =================== */}
                      {/* =================== */}
                      {/* =================== */}
                      {/* =================== */}
                      {/* =================== */}
                      {/* =================== */}
                      {/* =================== */}
                      {/* =================== */}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="background_gradient-parent">
          <div class="background_gradient"></div>
        </div>
        <img
          src="/img/dark_home_sec_bg.svg"
          alt=""
          className="home_div_section1_bg"
        />
      </section>
      {/* {errorModal ? (
        <UpdatedErrorModal
          errorMessage={errorMessage}
          closeModal={CloseErrorModal}
        />
      ) : null} */}
      {notDueDiv ? (
        <UpdatedWarningModal
          errorMessage={
            <div>
              An early withdrawal prior to the expiration of the stake period
              will result in a penalty fee of{" "}
              <span style={{ color: "#fff", fontWeight: "700" }}> 10%.</span>
            </div>
          }
          continueFunc={UnlockStake}
          closeModal={toggleNotDueDiv}
        />
      ) : null}
      {/* {successModal ? (
        <UpdatedSuccessModal
          btnRoute={true}
          successMessage={successMessage}
          route="/stake"
          txnHashDiv={true}
          TxnHash={txHash}
        />
      ) : null} */}
      {errorModal ? (
        <ErrorModal
          ErrorTxt={errorMessage}
          errorFunc={() => {
            setErrorModal(false);
          }}
        />
      ) : null}
      {successModal ? (
        <SuccessModal
          SuccesTxt={successMessage}
          successFunc={() => {
            window.location.href = "/stake";
          }}
          txnHashDiv={true}
          TxnHash={txHash}
        />
      ) : null}
    </div>
  );
};

export default Stake;
