import React, { useState, useEffect } from "react";
import { parseEther, formatEther } from "@ethersproject/units";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";
import { setStakeConfig } from "../../../web3";

const Function = () => {
  const context = useWeb3React();
  const { library, account } = context;

  const [eusdVal, setEusdVal] = useState("");
  const [interest, setInterest] = useState("");

  const eusdValOnchange = (e) => {
    setEusdVal(e.target.value);
    console.log(e.target.value);
  };
  const interestValOnchange = (e) => {
    setInterest(e.target.value);
    console.log(e.target.value);
  };

  const setConfig = async () => {
    console.log("====================================");
    console.log(
      eusdVal,
      parseEther(
        interest === "" ? "0".toString() : interest.toString(),
        "wei"
      ).toString()
    );
    console.log("====================================");
    const res = await setStakeConfig(
      eusdVal,
      parseEther(
        interest === "" ? "0".toString() : interest.toString(),
        "wei"
      ).toString(),
      library.getSigner()
    );
    console.log(res, "somto8uhhhg");
    console.log(res.status, "somto8uhhhg");
  };
  return (
    <div className="function_div ">
      <div className="container">
        <div className="function_div_area " style={{ marginTop: "4em" }}>
          <div className="function_div_area_1">
            <div className="function_div_area_1_cont1">
              Eusd Address
              <input
                type="text"
                value={eusdVal}
                className="function_div_area_1_input1"
                onChange={eusdValOnchange}
              />
            </div>
            <div className="function_div_area_1_cont1">
              Yearly Interest
              <input
                type="text"
                className="function_div_area_1_input1"
                value={interest}
                onChange={interestValOnchange}
              />
            </div>
          </div>
          <div className="function_div_area_2">
            {account ? (
              <button onClick={setConfig}>Call Function</button>
            ) : (
              <button>Connect Wallet</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Function;
