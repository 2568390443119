import { useEffect, useMemo, useState } from "react";
import "../../stylesheet/home.css";
import { Typewriter } from "react-simple-typewriter";
import Marquee from "react-fast-marquee";
import CheckIcon from "@mui/icons-material/Check";
import { routeHelper } from "../../helper";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

const Home = () => {
  const [init, setInit] = useState(false);
  const [videoDiv, setVideoDiv] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const ToggleVideoDiv = () => {
    setVideoDiv(!videoDiv);
  };
  const options = useMemo(
    () => ({
      // background: {
      //   color: {
      //     value: "#0d47a1",
      //   },
      // },
      // height: {
      //   value: "10px",
      // },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 10,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 100,
          enable: true,
          opacity: 0.3,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 150,
        },
        opacity: {
          value: 0.3,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  );
  return (
    <div className="home_div">
      {init ? (
        <div className="home_div_particles">
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />
        </div>
      ) : null}

      <section className="home_div_section1">
        <div className="container">
          <div className="home_div_section1_area">
            <div className="home_div_section1_area_cont">
              <div className="home_div_section1_area_cont1">
                <div className="home_div_section1_area_1">
                  <img
                    src="/img/register-gift.svg"
                    alt=""
                    className="home_div_section1_area_1_img"
                  />{" "}
                  Trade & Earn Program Trade on Egoxify and EARN $EUSD
                </div>
                <div className="home_div_section1_area_2">
                  <div className="home_div_section1_area_2_title">
                    <div className="home_div_section1_area_2_title_area1">
                      <div className="home_div_section1_area_2_title_typewriter_div">
                        <Typewriter
                          words={[" Trade with", "Earn from", "Borrow against"]}
                          cursor
                          cursorStyle=","
                          typeSpeed={80}
                          deleteSpeed={100}
                          loop={true}
                        />
                      </div>
                      on-chain real world assets in DeFi.
                    </div>
                    {/* <span className="home_div_section1_area_2_title_span"></span> */}
                  </div>
                  <div className="home_div_section1_area_2_para">
                    Earn competitive APYs by Saving eUSD, Borrow eUSD without
                    credit checks for your manufacturing needs, and convert eUSD
                    into 1000+ cryptocurrencies or 20+ fiat currencies.
                  </div>
                  <div
                    className="home_div_section1_area_2_btn_divs"
                    style={{ justifyContent: "flex-start" }}
                  >
                    {/* <a
                  href={routeHelper({ sub_domain: "cex", route: "" })}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {" "}
                  <button className="home_div_section1_area_2_btn_divs_1">
                    Explore Cex
                  </button>
                </a> */}
                    <a
                      href={routeHelper({ sub_domain: "dex", route: "stake" })}
                      style={{
                        // width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {" "}
                      <button className="home_div_section1_area_2_btn_divs_1">
                        Earn EUSD
                      </button>
                    </a>
                    <a
                      href={routeHelper({ sub_domain: "dex", route: "" })}
                      style={{
                        // width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {" "}
                      <button className="home_div_section1_area_2_btn_divs_2">
                        Trade EUSD
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="home_div_section1_area_cont2">
                <div className="home_div_section1_area_cont2_circle1"></div>
                <div className="home_div_section1_area_cont2_circle2"></div>
                <div className="home_div_section1_area_cont2_circle3"></div>
                <div className="home_div_section1_area_cont2_circle4"></div>
                <PlayArrowIcon className="playIcon" onClick={ToggleVideoDiv} />
                <video
                  className="videoElement"
                  id="my-vidss"
                  muted
                  autoPlay
                  playsInline
                  loop
                  poster="/img/egoxify_about.mp4"
                >
                  <source src="/img/egoxify_about.mp4" type="video/mp4" />
                </video>
              </div>
            </div>

            <div className="home_div_section1_area_stats">
              <div className="home_div_section1_area_stats_div1">
                <div className="home_div_section1_area_stats_div1_title">
                  10+
                </div>
                <div className="home_div_section1_area_stats_div1_para">
                  Block chains
                </div>
              </div>
              <div className="home_div_section1_area_stats_div1">
                <div className="home_div_section1_area_stats_div1_title">
                  1,000+
                </div>
                <div className="home_div_section1_area_stats_div1_para">
                  Cryptocurrencies
                </div>
              </div>
              <div className="home_div_section1_area_stats_div1">
                <div className="home_div_section1_area_stats_div1_title">
                  10+
                </div>
                <div className="home_div_section1_area_stats_div1_para">
                  Fiat Currencies
                </div>
              </div>
            </div>
            {/* <img src="/img/check.svg" alt="" /> */}
          </div>
        </div>
        <img src="/img/4880206.jpg" alt="" className="home_div_section1_img" />
        <div class="background_gradient-parent">
          <div class="background_gradient"></div>
        </div>
        <img
          src="/img/dark_home_sec_bg.svg"
          alt=""
          className="home_div_section1_bg"
        />
      </section>
      {/* =============== */}
      {/* =============== */}
      {/* =============== */}
      {/* =============== */}
      <section className="home_div_section2">
        <div className="container">
          <div className="home_div_section2_area">
            <div className="home_div_section2_area2">
              <div class="home_div_section2_area2_circle_line1">
                <img
                  src="/img/klatyn_icon.png"
                  alt=""
                  className="home_div_section2_area2_area_cont1_img"
                />
                <img
                  src="/img/celo_img.webp"
                  alt=""
                  className="home_div_section2_area2_area_cont1_img2"
                />
                <img
                  src="/img/matic_icon.jpeg"
                  alt=""
                  className="home_div_section2_area2_area_cont1_img3"
                />
                <img
                  src="/img/fantom_icon.png"
                  alt=""
                  className="home_div_section2_area2_area_cont1_img4"
                />
                <img
                  src="/img/avax_fade_icon.png"
                  alt=""
                  className="home_div_section2_area2_circle_line2_avax_img"
                />
              </div>
              <div class="home_div_section2_area2_circle_line2"></div>

              {/* <div class="home_div_section2_area2_circle_line3"></div> */}
            </div>
            <div className="home_div_section2_area1">
              Maximize on-chain yield through multi-network diversification.
            </div>
          </div>
        </div>
      </section>
      {/* =============== */}
      {/* =============== */}
      {/* =============== */}
      {/* =============== */}
      <section className="home_div_section3">
        <div className="custom2_container">
          <div className="home_div_section3_area">
            <div className="home_div_section3_area_cont1">
              <div className="home_div_section3_area_cont1_div1">
                <div className="home_div_section3_area_cont1_div1_cont1">
                  Earn on egoxify
                </div>
                <div className="home_div_section3_area_cont1_div1_cont2">
                  Maximize your returns by leveraging eUSD savings opportunities
                </div>
                <div className="home_div_section3_area_cont1_div1_cont3">
                  Enjoy auto-compounding interest based on the dynamic global
                  system variable, the ESR (eUSD Stable Rate). This interest
                  accrues seamlessly from the debt positions established by
                  Manufacturers, offering a reliable and efficient passive
                  income stream.
                </div>
                <a
                  href={routeHelper({ sub_domain: "dex", route: "stake" })}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button className="home_div_section3_area_cont1_btn">
                    Earn
                  </button>
                </a>
              </div>
              <div className="home_div_section3_area_cont1_div2">
                <img
                  src="/img/earn_card_img.svg"
                  alt=""
                  className="home_div_section3_area_cont1_div2_img"
                />
                <img
                  src="/img/features_div_below_bg.svg"
                  alt=""
                  className="home_div_section3_area_cont1_div2_bg"
                />
              </div>
            </div>

            <div className="home_div_section3_area_cont2">
              <div className="home_div_section3_area_cont1_div1">
                <div className="home_div_section3_area_cont1_div1_cont1">
                  Trade on egoxify
                </div>
                <div className="home_div_section3_area_cont1_div1_cont2">
                  Trade in On-Chain Real-World DeFi.
                </div>
                <div className="home_div_section3_area_cont1_div1_cont3">
                  Execute trades in 1000+ crypto assets across 10 diverse
                  blockchains, experiencing zero slippage and unparalleled
                  on-chain liquidity. Seamlessly convert your holdings to 20+
                  fiat currencies with frictionless interoperability.
                </div>
                <div className="home_div_section3_area_cont1_btn_divs">
                  <a
                    href={routeHelper({ sub_domain: "dex", route: "" })}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <button className="home_div_section3_area_cont1_btn2">
                      View Dex
                    </button>
                  </a>
                </div>
              </div>
              <div className="home_div_section3_area_cont1_div2">
                <img
                  src="/img/trade_img.svg"
                  alt=""
                  className="home_div_section3_area_cont1_div2_img"
                />
                <img
                  src="/img/features_div_below_bg.svg"
                  alt=""
                  className="home_div_section3_area_cont1_div2_bg"
                />
              </div>
            </div>

            <div className="home_div_section3_area_cont4">
              <div className="home_div_section3_area_cont1_div1">
                <div className="home_div_section3_area_cont1_div1_cont1">
                  Borrow on egoxify
                </div>
                <div className="home_div_section3_area_cont1_div1_cont2">
                  Unlock unparalleled financing opportunities for your
                  manufacturing needs.
                </div>
                <div className="home_div_section3_area_cont1_div1_cont3">
                  Secure instant, affordable capital without credit checks or
                  complex paperwork. Utilize your existing machinery and
                  inventory as collateral, ensuring maximum efficiency and a
                  streamlined approval process. This over-collateralized model
                  empowers you to achieve competitive pricing and accelerate
                  your manufacturing potential.
                </div>
                <a
                  href="#"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button className="home_div_section3_area_cont1_btn">
                    Explore
                  </button>
                </a>
              </div>
              <div className="home_div_section3_area_cont1_div2">
                <img
                  src="/img/borrow_card_img.svg"
                  alt=""
                  className="home_div_section3_area_cont1_div2_img"
                />
                <img
                  src="/img/features_div_below_bg.svg"
                  alt=""
                  className="home_div_section3_area_cont1_div2_bg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============== */}
      {/* =============== */}
      {/* =============== */}
      {/* =============== */}
      <section className="home_div_section4">
        <div className="container">
          <div className="home_div_section4_area_2_area1_title">
            Why Choose Us
            <span className="home_div_section4_area_2_para">
              Empowering Inclusion & Efficiency in Securitization
            </span>
          </div>
          <div className="home_div_section4_area">
            <div className="home_div_section4_area_1">
              <Marquee
                speed={80}
                gradient={false}
                autoFill={true}
                direction="up"
                // delay={0}
              >
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/avax_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">AVAX</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/klatyn_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">KLATYN</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/celo_img.webp"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">CELO</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/matic_icon.jpeg"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">MATIC</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/fantom_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">FANTOM</div>
                </div>
              </Marquee>
              <Marquee
                speed={80}
                gradient={false}
                autoFill={true}
                direction="down"
                // delay={0}
              >
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/avax_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">AVAX</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/klatyn_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">KLATYN</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/celo_img.webp"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">CELO</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/matic_icon.jpeg"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">MATIC</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/fantom_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">FANTOM</div>
                </div>
              </Marquee>
              <Marquee
                speed={80}
                gradient={false}
                autoFill={true}
                direction="up"
                // delay={0}
              >
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/avax_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">AVAX</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/klatyn_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">KLATYN</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/celo_img.webp"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">CELO</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/matic_icon.jpeg"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">MATIC</div>
                </div>
                <div className="home_div_section4_area_1_area">
                  <div className="home_div_section4_area_1_area_1">
                    <img
                      src="/img/fantom_icon.png"
                      alt=""
                      className="home_div_section4_area_1_area_1_webp"
                    />
                  </div>
                  <div className="home_div_section4_area_1_area_2">FANTOM</div>
                </div>
              </Marquee>
            </div>
            <div className="home_div_section4_area_2">
              <div className="home_div_section4_area_2_body">
                <div className="home_div_section4_area_2_body_cont1">
                  <div className="home_div_section4_area_2_body_cont1_icon">
                    <CheckIcon className="home_div_section4_area_2_body_cont1_icon_img" />
                  </div>
                  <div className="home_div_section4_area_2_body_cont1_txt_div">
                    <div className="home_div_section4_area_2_body_cont1_txt_div_title">
                      Democratized Governance
                    </div>
                    <div className="home_div_section4_area_2_body_cont1_txt_div_para">
                      Decentralized on-chain governance ensures equal
                      opportunity for all stakeholders – from issuers proposing
                      new structures to underwriters and servicers shaping their
                      execution. This open access empowers collaboration and
                      transparency throughout the securitization process.
                    </div>
                  </div>
                </div>
                <div className="home_div_section4_area_2_body_cont1">
                  <div className="home_div_section4_area_2_body_cont1_icon">
                    <CheckIcon className="home_div_section4_area_2_body_cont1_icon_img" />
                  </div>
                  <div className="home_div_section4_area_2_body_cont1_txt_div">
                    <div className="home_div_section4_area_2_body_cont1_txt_div_title">
                      Cost-Optimized Architecture
                    </div>
                    <div className="home_div_section4_area_2_body_cont1_txt_div_para">
                      Community driven fair launch. The dev turned burned all of
                      Our decentralized infrastructure bypasses the costly
                      complexities of traditional off-chain processes.
                      Open-source services further streamline operations and
                      reduce intermediary fees, minimizing the overall capital
                      outlay for securitization.
                    </div>
                  </div>
                </div>
                <div className="home_div_section4_area_2_body_cont1">
                  <div className="home_div_section4_area_2_body_cont1_icon">
                    <CheckIcon className="home_div_section4_area_2_body_cont1_icon_img" />
                  </div>
                  <div className="home_div_section4_area_2_body_cont1_txt_div">
                    <div className="home_div_section4_area_2_body_cont1_txt_div_title">
                      Unwavering Transparency
                    </div>
                    <div className="home_div_section4_area_2_body_cont1_txt_div_para">
                      Gain crystal-clear visibility into every aspect of the
                      debt facility by bringing the entire capital structure,
                      securitization details, and servicing activities onto the
                      blockchain. Multi-tranche structures and comprehensive
                      reporting offer unparalleled transparency for all
                      participants.
                    </div>
                  </div>
                </div>
                <div className="home_div_section4_area_2_body_cont1">
                  <div className="home_div_section4_area_2_body_cont1_icon">
                    <CheckIcon className="home_div_section4_area_2_body_cont1_icon_img" />
                  </div>
                  <div className="home_div_section4_area_2_body_cont1_txt_div">
                    <div className="home_div_section4_area_2_body_cont1_txt_div_title">
                      Secure & Compliant
                    </div>
                    <div className="home_div_section4_area_2_body_cont1_txt_div_para">
                      Rest assured, our protocol operates with strict legal
                      recourse and adheres to robust regulatory and compliance
                      requirements, including KYC, sanctions screenings, and
                      investor accreditation checks. This commitment to security
                      and compliance builds trust and protects all parties
                      involved.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============== */}
      {/* =============== */}
      {/* =============== */}
      {/* =============== */}
      <section className="home_div_section5">
        <div className="container">
          <div className="home_div_section5_area">
            <div className="home_div_section5_area_head">
              {/* <div className="home_div_section5_area_head_para">Join Us</div> */}
              <div className="home_div_section5_area_head_title">
                Ready to get started?
              </div>
            </div>
            <div className="home_div_section1_area_2_btn_divs">
              <a
                href={routeHelper({ sub_domain: "dex", route: "stake" })}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {" "}
                <button className="home_div_section1_area_2_btn_divs_1">
                  Earn EUSD
                </button>
              </a>
              <a
                href={routeHelper({ sub_domain: "dex", route: "" })}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {" "}
                <button className="home_div_section1_area_2_btn_divs_2">
                  Trade EUSD
                </button>
              </a>
            </div>
          </div>
        </div>
        <img src="/img/4880206.jpg" alt="" className="home_div_section1_img" />
        <div class="background_gradient-parent">
          <div class="background_gradient"></div>
        </div>
        <img
          src="/img/dark_home_sec_bg.svg"
          alt=""
          className="home_div_section1_bg"
        />
      </section>
      {videoDiv ? (
        <div className="YtVideoDiv">
          <CloseIcon
            className="YtVideoDiv_closeIcon"
            onClick={ToggleVideoDiv}
          />
          <div className="YtVideoDiv_close_div"></div>
          <iframe
            width="1280"
            height="720"
            src="https://www.youtube.com/embed/XFEcu_NLpsw"
            title="Egoxify Brand Story"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      ) : null}
    </div>
  );
};

export default Home;
