import React from "react";
import { Route, Routes } from "react-router-dom";
import CexRoutes from "./Routes";
import CexHome from "./pages/CexHome";
import Signup from "./pages/Signup/Signup";
import { Provider } from "react-redux";
import CefiHeader from "../../Header/CefiHeader";

import { store } from "./app/store";
import Login from "./pages/Login/Login";
import KycPage from "./pages/KYC/KycPage";
import DashboardWallets from "./pages/Wallet/DashboardWallets";
import Swap from "./pages/Swap/Swap";
import EmailVerification from "./pages/Verification/Appverification";
// import store from "./app/store";
const RouteConfig = ({ darkMode, togglemakeDarkUi }) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <CexRoutes
              darkMode={darkMode}
              togglemakeDarkUi={togglemakeDarkUi}
            />
          }
        >
          <Route path="" element={<Swap />} />
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Login />} />
          <Route path="kyc" element={<KycPage />} />
          <Route path="wallet" element={<DashboardWallets />} />
          <Route
            path="verify/email/address/:id"
            element={<EmailVerification />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default RouteConfig;
