import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { verifyUser } from "./features/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Header/Header";
import CefiHeader from "../../Header/CefiHeader";
import { fetchWalletBalance } from "./features/walletServices/walletActions";
import Footer from "../../Footer/Footer";
const CexRoutes = ({ darkMode, togglemakeDarkUi }) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);

  const verify_user = async () => {
    // const response = await VERIFY_USER();
    const response = dispatch(verifyUser());
  };

  const fetch_walllet = async () => {
    // const response = await VERIFY_USER();
    // const response = await VERIFY_USER();

    if (!user && loading === false) {
      return;
    }
    const response = await dispatch(fetchWalletBalance());
  };
  useEffect(() => {
    fetch_walllet();
  }, [user, loading]);
  useEffect(() => {
    verify_user();
  }, []);
  return (
    <div>
      <CefiHeader darkMode={darkMode} togglemakeDark={togglemakeDarkUi} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default CexRoutes;
