const StaticData = {
  BaseToken: [
    {
      id: "0",
      img: "/img/tokens-folder/busd_icon.png",
      symbol: "EUSD",
      PriceAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      address: "0xBDeb3C052bD949B6E38Cb0BC9593793a78c46968",
      name: "EUSD Token",
      favorite: "true",
    },
  ],
  AssetsToken: [
    {
      id: "1",
      img: "/img/tokens-folder/bnb_icon.png",
      name: "Binance Chain Token",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      symbol: "BNB",
      favorite: "true",
    },
    {
      id: "2",
      img: "/img/tokens-folder/usdt_icon.png",
      name: "Tether USD",
      address: "0x55d398326f99059fF775485246999027B3197955",
      symbol: "USDT",
      favorite: "true",
    },
    {
      id: "3",
      img: "/img/tokens-folder/usdsc_icon.png",
      name: "Binance Pegged USDC Coin",
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      symbol: "USDC",
      favorite: "true",
    },
    {
      id: "4",
      img: "/img/tokens-folder/cake_icon.png",
      name: "Pancakeswap Token",
      address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      symbol: "CAKE",
      favorite: "false",
    },
    {
      id: "5",
      img: "/img/tokens-folder/file_icon.png",
      name: "Binance-Peg File coin",
      address: "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
      symbol: "FIL",
      favorite: "false",
    },
    {
      id: "6",
      img: "/img/tokens-folder/eth_icon.png",
      name: "Binance-Peg Ethereum Token",
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      symbol: "ETH",
      favorite: "true",
    },
    {
      id: "7",
      img: "/img/tokens-folder/dai_icon.png",
      name: "Binance-Peg Dai ",
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      symbol: "DAI",
      favorite: "true",
    },
    {
      id: "8",
      img: "/img/tokens-folder/xrp_icon.png",
      name: "Binance-Peg XRP Token ",
      address: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
      symbol: "XRP",
      favorite: "false",
    },
    {
      id: "9",
      img: "/img/tokens-folder/ada_icon.png",
      name: "Binance-Peg Cardano Token ",
      address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      symbol: "ADA",
      favorite: "false",
    },
    {
      id: "10",
      img: "/img/tokens-folder/matic_icon.png",
      name: "Binance-Peg Polygon ",
      address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
      symbol: "MATIC",
      favorite: "true",
    },
    {
      id: "11",
      img: "/img/tokens-folder/trust_token_icon.png",
      name: "Trust Wallet Token ",
      address: "0x4B0F1812e5Df2A09796481Ff14017e6005508003",
      symbol: "TWT",
      favorite: "false",
    },
    {
      id: "12",
      img: "/img/tokens-folder/dot_token.png",
      name: "Binance-Peg Polkadot Token ",
      address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
      symbol: "DOT",
      favorite: "true",
    },
    {
      id: "13",
      img: "/img/tokens-folder/chain_link_icon.png",
      name: "Binance-Peg ChainLink Token ",
      address: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
      symbol: "LINK",
      favorite: "false",
    },
    {
      id: "14",
      img: "/img/tokens-folder/lite_coin_icon.png",
      name: " Binance-Peg Litecoin Token ",
      address: "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
      symbol: "LTC",
      favorite: "false",
    },
    {
      id: "15",
      img: "/img/tokens-folder/avax_icon.png",
      name: " Binance-Peg Avalanche Token ",
      address: "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
      symbol: "AVAX",
      favorite: "false",
    },
    {
      id: "16",
      img: "/img/tokens-folder/tusd_icon.png",
      name: " Binance-Peg TrueUsd Token ",
      address: "0x14016E85a25aeb13065688cAFB43044C2ef86784",
      symbol: "TUSD",
      favorite: "false",
    },
  ],

  Proposals: [
    {
      id: "1",
      qty: "10",
      title: "Production of 10 E.V sedans",
      amount: 50000,
      creator: "0x3dE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal, if passed, will:Activate block rewards and mint 20096 CFG each epoch into the on-chain Treasury.Set daily collator rewards in line with epoch - 16.65CFG/epochSet epoch period for rewards - 12 hours",
      votes: [
        {
          id: "1",
          voter: "0x3dE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "6-11-23",
        },
        {
          id: "2",
          voter: "0x5dE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "6-11-23",
        },
        {
          id: "3",
          voter: "0x6af79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "8-11-23",
        },
        {
          id: "4",
          voter: "0x5da79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "11-11-23",
        },
        {
          id: "5",
          voter: "0x7ad79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "11-11-23",
        },
        {
          id: "6",
          voter: "0xbcE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "13-11-23",
        },
      ],
      startDate: "1-11-23",
      endDate: "16-12-23",
      status: "Active",
    },
    {
      id: "2",
      qty: "10",
      title: "Production of 5 lithium batteries",
      amount: 1000,
      creator: "0x3dE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal, if passed, will:Activate block rewards and mint 20096 CFG each epoch into the on-chain Treasury.Set daily collator rewards in line with epoch - 16.65CFG/epochSet epoch period for rewards - 12 hours",
      votes: [
        {
          id: "1",
          voter: "0x3dE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "6-10-23",
        },
        {
          id: "2",
          voter: "0x5dE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "6-10-23",
        },
        {
          id: "3",
          voter: "0x6af79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "8-10-23",
        },
        {
          id: "4",
          voter: "0x5da79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "11-10-23",
        },
        {
          id: "5",
          voter: "0x7ad79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "11-10-23",
        },
        {
          id: "6",
          voter: "0xbcE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "13-10-23",
        },
      ],
      startDate: "5-09-23",
      endDate: "11-11-23",
      status: "Approved",
    },
    {
      id: "3",
      qty: "10",
      title: "Production of 8 E.V sedans",
      amount: 40000,
      creator: "0x3dE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal, if passed, will:Activate block rewards and mint 20096 CFG each epoch into the on-chain Treasury.Set daily collator rewards in line with epoch - 16.65CFG/epochSet epoch period for rewards - 12 hours",
      votes: [
        {
          id: "1",
          voter: "0x3dE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "6-09-23",
        },
        {
          id: "2",
          voter: "0x5dE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "6-09-23",
        },
        {
          id: "3",
          voter: "0x6af79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "8-09-23",
        },
        {
          id: "4",
          voter: "0x5da79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "11-10-23",
        },
        {
          id: "5",
          voter: "0x7ad79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "11-10-23",
        },
        {
          id: "6",
          voter: "0xbcE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "13-10-23",
        },
      ],
      startDate: "5-09-23",
      endDate: "11-11-23",
      status: "Terminated",
    },
    {
      id: "4",
      qty: "10",
      title: "Production of 100 lithium batteries",
      amount: 10000,
      creator: "0x3dE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal, if passed, will:Activate block rewards and mint 20096 CFG each epoch into the on-chain Treasury.Set daily collator rewards in line with epoch - 16.65CFG/epochSet epoch period for rewards - 12 hours",
      votes: [],
      startDate: "6-09-23",
      endDate: "10-10-23",
      status: "Withdrawn",
    },
    {
      id: "5",
      qty: "10",
      title: "Renovation of 20 Smart Homes",
      amount: 75000,
      creator: "0x8aF79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to upgrade and modernize 20 smart homes with the latest technology and energy-efficient systems.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "4-12-23",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "5-12-23",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "7-12-23",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-12-23",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-12-23",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-12-23",
        },
      ],
      startDate: "2-12-23",
      endDate: "20-01-24",
      status: "Approved",
    },
    {
      id: "6",
      qty: "10",
      title: "Installation of Solar Panels in 50 Schools",
      amount: 120000,
      creator: "0x4bD79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to make 50 schools more energy-independent by installing solar panels on their rooftops.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "8-12-23",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-12-23",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-12-23",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-12-23",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "18-12-23",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "20-12-23",
        },
      ],
      startDate: "5-01-24",
      endDate: "28-02-24",
      status: "Active",
    },
    {
      id: "7",
      qty: "10",
      title: "Planting 5000 Trees in Urban Areas",
      amount: 30000,
      creator: "0x7cE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to improve the urban environment by planting 5000 trees in strategic locations to enhance green spaces and air quality.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "3-01-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "5-01-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "7-01-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-01-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-01-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-01-24",
        },
      ],
      startDate: "2-01-24",
      endDate: "15-02-24",
      status: "Approved",
    },
    {
      id: "8",
      qty: "10",
      title: "Development of a Community Sports Complex",
      amount: 200000,
      creator: "0x8bE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal seeks funding to build a community sports complex with facilities for various sports, promoting a healthy lifestyle and community engagement.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "8-01-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-01-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-01-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-01-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "18-01-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "20-01-24",
        },
      ],
      startDate: "5-02-24",
      endDate: "30-03-24",
      status: "Active",
    },
    {
      id: "9",
      qty: "10",
      title: "Community Health and Wellness Program",
      amount: 50000,
      creator: "0x9cE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to establish a comprehensive health and wellness program for the community, including fitness classes, nutritional education, and regular health check-ups.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "3-02-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "5-02-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "7-02-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-02-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-02-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-02-24",
        },
      ],
      startDate: "2-02-24",
      endDate: "15-03-24",
      status: "Approved",
    },
    {
      id: "10",
      qty: "10",
      title: "Digital Literacy Program for Seniors",
      amount: 30000,
      creator: "0xAbE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to provide digital literacy training to seniors, empowering them to use technology for communication, entertainment, and accessing information.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "8-02-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-02-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-02-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-02-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "18-02-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "20-02-24",
        },
      ],
      startDate: "5-03-24",
      endDate: "28-04-24",
      status: "Active",
    },
    {
      id: "11",
      qty: "10",
      title: "Art and Cultural Festival",
      amount: 80000,
      creator: "0xBbE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to organize an art and cultural festival to showcase local talent, foster creativity, and celebrate the diversity of our community.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "3-03-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "5-03-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "7-03-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-03-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-03-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-03-24",
        },
      ],
      startDate: "2-03-24",
      endDate: "15-04-24",
      status: "Approved",
    },
    {
      id: "12",
      qty: "10",
      title: "Clean Energy Initiative",
      amount: 150000,
      creator: "0xCcE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to invest in clean energy solutions such as solar panels and wind turbines to reduce the carbon footprint of our community.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "8-03-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-03-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-03-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-03-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "18-03-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "20-03-24",
        },
      ],
      startDate: "5-04-24",
      endDate: "30-05-24",
      status: "Active",
    },
    {
      id: "13",
      qty: "10",
      title: "Youth Empowerment Program",
      amount: 40000,
      creator: "0xDdE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to establish a youth empowerment program providing education, mentorship, and skill development to empower the next generation.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "3-04-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "5-04-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "7-04-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-04-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-04-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-04-24",
        },
      ],
      startDate: "2-04-24",
      endDate: "15-05-24",
      status: "Approved",
    },
    {
      id: "14",
      qty: "10",
      title: "Renewable Energy Research",
      amount: 120000,
      creator: "0xEeE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to fund research projects focused on advancing renewable energy technologies to address environmental challenges.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "8-04-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-04-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-04-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-04-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "18-04-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "20-04-24",
        },
      ],
      startDate: "5-05-24",
      endDate: "30-06-24",
      status: "Active",
    },
    {
      id: "15",
      qty: "10",
      title: "Community Garden Expansion",
      amount: 35000,
      creator: "0xFfE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to expand the community garden, providing more green spaces for residents to cultivate their own produce and fostering a sense of community.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "3-05-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "5-05-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "7-05-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-05-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-05-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-05-24",
        },
      ],
      startDate: "2-05-24",
      endDate: "15-06-24",
      status: "Approved",
    },
    {
      id: "16",
      qty: "10",
      title: "STEM Education Initiative",
      amount: 90000,
      creator: "0x0hE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to enhance STEM (Science, Technology, Engineering, and Mathematics) education in local schools, providing resources and programs to inspire students.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "8-05-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-05-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-05-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-05-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "18-05-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "20-05-24",
        },
      ],
      startDate: "5-06-24",
      endDate: "30-07-24",
      status: "Active",
    },
    {
      id: "17",
      qty: "10",
      title: "Microfinance Support for Entrepreneurs",
      amount: 60000,
      creator: "0x1iE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to provide microfinance support to local entrepreneurs, helping them start or expand their small businesses and contribute to economic growth.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "3-06-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "5-06-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "7-06-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-06-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-06-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-06-24",
        },
      ],
      startDate: "2-06-24",
      endDate: "15-07-24",
      status: "Approved",
    },
    {
      id: "18",
      qty: "10",
      title: "Community Library Renovation",
      amount: 45000,
      creator: "0x2jE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to renovate the community library, providing an improved and welcoming space for residents to access educational resources and engage in learning activities.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "3-07-24",
        },
      ],
      startDate: "5-07-24",
      endDate: "20-08-24",
      status: "Active",
    },
    {
      id: "19",
      qty: "10",
      title: "Urban Green Spaces Project",
      amount: 75000,
      creator: "0x3kE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to create and maintain green spaces within urban areas, providing residents with a place to relax, exercise, and connect with nature.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "3-08-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "5-08-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "7-08-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-08-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-08-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-08-24",
        },
      ],
      startDate: "2-08-24",
      endDate: "15-09-24",
      status: "Approved",
    },
    {
      id: "20",
      qty: "10",
      title: "Senior Citizen Support Program",
      amount: 50000,
      creator: "0x4lE79168402278C0DA2Bf9A209C3A91d755790FC",
      description:
        "This proposal aims to establish a support program for senior citizens, providing assistance with daily tasks, social activities, and healthcare.",
      votes: [
        {
          id: "1",
          voter: "0x1aB79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "8-08-24",
        },
        {
          id: "2",
          voter: "0x2bC79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "10-08-24",
        },
        {
          id: "3",
          voter: "0x3cD79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "12-08-24",
        },
        {
          id: "4",
          voter: "0x4eE79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "15-08-24",
        },
        {
          id: "5",
          voter: "0x5fF79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "No",
          date: "18-08-24",
        },
        {
          id: "6",
          voter: "0x6aA79168402278C0DA2Bf9A209C3A91d755790FC",
          type: "Yes",
          date: "20-08-24",
        },
      ],
      startDate: "5-09-24",
      endDate: "28-10-24",
      status: "Active",
    },
  ],
};

export default StaticData;
