import React, { useState, useEffect } from "react";
import "../../stylesheet/header.css";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { routeHelper } from "../../helper";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const CefiHeader = ({ darkMode, togglemakeDark }) => {
  const { user } = useSelector((state) => state.auth);
  const [lightMode, setLightMode] = useState(true);
  const [tradeDrop, setTradeDrop] = useState(false);
  const [headerMenu, setHeaderMenu] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const ToggleLightMode = () => {
    setLightMode(!lightMode);
  };
  const ToggleTradeDrop = () => {
    setTradeDrop(!tradeDrop);
  };
  const ToggleHeaderMenu = () => {
    setHeaderMenu(!headerMenu);
  };
  console.log(user);

  const ToggleSettingsMenu = () => {
    setSettingsMenu(!settingsMenu);
  };
  return (
    <div className="header_div">
      <div className="container">
        <div className="header_div_area">
          <div className="header_div_area_cont1">
            <img
              src="/egox1.png"
              alt=""
              className="header_div_area_cont1_img"
            />
            Egoxify
            {/* <span className="header_div_area_cont1_span">x</span> */}
            <div className="header_div_area_cont2">
              <div
                href="#"
                className="header_div_area_cont2_link1"
                onClick={ToggleTradeDrop}
              >
                Trade
                {tradeDrop ? (
                  <KeyboardArrowUpIcon className="header_div_area_cont2_link1_icon" />
                ) : (
                  <KeyboardArrowDownIcon className="header_div_area_cont2_link1_icon" />
                )}
                {tradeDrop ? (
                  <div className="tradeDropDiv">
                    <a href="/" className="tradeDropDiv_link">
                      Centralized Ex
                    </a>
                    <a
                      href={routeHelper({ sub_domain: "dex", route: "" })}
                      className="tradeDropDiv_link"
                    >
                      Decentralized Ex
                    </a>
                  </div>
                ) : null}
              </div>
              <a href="/wallet" className="header_div_area_cont2_link1">
                Wallet
              </a>
            </div>
          </div>

          <div className="header_div_area_cont3">
            {user === null || user === undefined || !user ? (
              <div className="header_div_area_cont3_login_mobile">
                <a href="/login">
                  {" "}
                  <button className="header_div_area_cont3_div3_btn">
                    Login/Signup
                  </button>
                </a>
              </div>
            ) : null}
            <MenuIcon
              className="header_div_area_cont3_icon"
              onClick={ToggleHeaderMenu}
            />
            <div className="header_div_area_cont3_div3">
              {user === null || user === undefined || !user ? (
                <a href="/login">
                  {" "}
                  <button className="header_div_area_cont3_div3_btn">
                    Login/Signup
                  </button>
                </a>
              ) : (
                <div className="header_div_area_cont3_div3_area">
                  <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                      <AccountCircleIcon className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_icon" />
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                        {user.username}
                      </div>
                    </div>
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                      {`${"23456".slice(0, 4)}...${"97899976".slice(5, 7)}`}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="header_div_area_cont3_div2"
              onClick={ToggleSettingsMenu}
            >
              <SettingsIcon className="header_div_area_cont3_div2_icon" />
              {settingsMenu ? (
                <div className="header_settings_div">
                  <div className="header_settings_div_cont_1">
                    <span className="header_settings_div_cont_1_span1">
                      UI-mode
                    </span>
                    <span className="header_settings_div_cont_1_span2">
                      {darkMode ? (
                        <Brightness7Icon
                          className="header_div_area_cont3_div2_icon"
                          onClick={togglemakeDark}
                        />
                      ) : (
                        <NightsStayIcon
                          className="header_div_area_cont3_div2_icon"
                          onClick={togglemakeDark}
                        />
                      )}
                    </span>
                  </div>
                  <div className="header_settings_div_cont_1">
                    <span className="header_settings_div_cont_1_span1">
                      Language
                    </span>
                    <span className="header_settings_div_cont_1_span2">
                      <img
                        src="/img/language_select_img1.svg"
                        alt=""
                        className="header_div_area_cont3_img"
                      />
                    </span>
                  </div>
                  {user === null || user === undefined || !user ? null : (
                    <div className="header_settings_div_cont_1">
                      <span className="header_settings_div_cont_1_span1">
                        Logout
                      </span>
                      <span className="header_settings_div_cont_1_span2">
                        <div
                          className="header_div_area_cont3_div2"
                          onClick={() => {
                            localStorage.removeItem("x-token");
                            window.location.href = "/login";
                          }}
                        >
                          <PowerSettingsNewIcon
                            className="header_div_area_cont3_div2_icon"
                            style={{ color: "red" }}
                          />
                        </div>
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {headerMenu ? (
        <div className="headerMenuDiv">
          <div className="headerMenuDiv_cont">
            <CloseIcon
              className="header_div_area_cont3_icon2"
              onClick={ToggleHeaderMenu}
            />
            <a href="/" className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Centralized Ex </span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <a
              href={routeHelper({ sub_domain: "dex", route: "" })}
              className="headerMenuDiv_cont_1"
            >
              {" "}
              <span className="headerMenuDiv_cont_1_txt">
                De-Centralized Ex{" "}
              </span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <a href="/wallet" className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Wallet</span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">User Info</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {user === null || user === undefined || !user ? (
                  <div className="header_div_area_cont3_div3_area">
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                        <AccountCircleIcon className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_icon" />
                        <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                          @...
                        </div>
                      </div>
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                        000...
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="header_div_area_cont3_div3_area">
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                        <AccountCircleIcon className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_icon" />
                        <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                          {user.username}
                        </div>
                      </div>
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                        {`${"23456".slice(0, 4)}...${"97899976".slice(5, 7)}`}
                      </div>
                    </div>
                  </div>
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Logout</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {user === null || user === undefined || !user ? null : (
                  <div
                    className="header_div_area_cont3_div2_mobile"
                    onClick={() => {
                      localStorage.removeItem("x-token");
                      window.location.href = "/login";
                    }}
                  >
                    <PowerSettingsNewIcon
                      className="header_div_area_cont3_div2_icon"
                      style={{ color: "red" }}
                    />
                  </div>
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">UI-mode</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {darkMode ? (
                  <Brightness7Icon
                    className="header_div_area_cont3_div2_icon"
                    onClick={togglemakeDark}
                  />
                ) : (
                  <NightsStayIcon
                    className="header_div_area_cont3_div2_icon"
                    onClick={togglemakeDark}
                  />
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Language</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                <img
                  src="/img/language_select_img1.svg"
                  alt=""
                  className="header_div_area_cont3_img"
                />
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CefiHeader;
