import React from "react";
import "./egox.css";
import Roadmap from "./Roadmap";
const Egox = () => {
  return (
    <div className="egox_token_div">
      <section className="egox_token_div_section1">
        <div className="container">
          <div className="egox_token_div_section1area">
            <div className="egox_token_div_section1area_cont1">
              <div className="egox_token_div_section1area1">
                <div className="egox_token_div_section1area1_title">
                  XIFY token
                </div>
                <div className="egox_token_div_section1area1_para">
                  Empowering Investors and Shaping the Egoxify Ecosystem.
                </div>
              </div>
              <div className="egox_token_div_section1area2">
                <img
                  src="/img/1_inch_token_image_dummy.svg"
                  alt=""
                  className="egox_token_div_section1area2_img"
                />
              </div>
            </div>
            <div className="egox_token_div_section1area_cont2">
              <div className="home_div_section1_area_stats_div1">
                <div className="home_div_section1_area_stats_div1_title">
                  $0.00
                </div>
                <div className="home_div_section1_area_stats_div1_para">
                  Current Price
                </div>
              </div>
              <div className="home_div_section1_area_stats_div1">
                <div className="home_div_section1_area_stats_div1_title">
                  0.00
                </div>
                <div className="home_div_section1_area_stats_div1_para">
                  Circulating Supply
                </div>
              </div>
              <div className="home_div_section1_area_stats_div1">
                <div className="home_div_section1_area_stats_div1_title">
                  280M
                </div>
                <div className="home_div_section1_area_stats_div1_para">
                  Total Supply
                </div>
              </div>
              <div className="home_div_section1_area_stats_div1">
                <div className="home_div_section1_area_stats_div1_title">0</div>
                <div className="home_div_section1_area_stats_div1_para">
                  Holders
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      <section className="egox_token_div_section2">
        <div className="container">
          <div className="egox_token_div_section2_area">
            <div className="egox_token_div_section2_area_title">
              XIFY Utility
            </div>
            <div className="egox_token_div_section2_area_body">
              <div className="egox_token_div_section2_area_body_cont1">
                <div className="egox_token_div_section2_area_body_cont1_icon_div">
                  <img
                    src="/img/governance_icon.svg"
                    alt=""
                    className="egox_token_div_section2_area_body_cont1_icon_div_img"
                  />
                </div>
                <div className="egox_token_div_section2_area_body_cont1_title">
                  Governance Participation
                </div>
                <div className="egox_token_div_section2_area_body_cont1_para">
                  XIFY is the governance token within the Egoxify ecosystem.
                  Holders actively participate in governance decisions,
                  including voting on critical matters such as protocol
                  upgrades, partnerships, and other platform-related changes.
                </div>
              </div>
              <div className="egox_token_div_section2_area_body_cont1">
                <div className="egox_token_div_section2_area_body_cont1_icon_div">
                  <img
                    src="/img/staking_icon.svg"
                    alt=""
                    className="egox_token_div_section2_area_body_cont1_icon_div_img"
                  />
                </div>
                <div className="egox_token_div_section2_area_body_cont1_title">
                  Staking for Ecosystem Stability
                </div>
                <div className="egox_token_div_section2_area_body_cont1_para">
                  Investors can stake their XIFY tokens in designated pools,
                  contributing to the stability and security of the ecosystem.
                  Stakers earn rewards, fostering active participation while
                  supporting the overall health of the network.
                </div>
              </div>
              <div className="egox_token_div_section2_area_body_cont1">
                <div className="egox_token_div_section2_area_body_cont1_icon_div">
                  <img
                    src="/img/fee_icon.svg"
                    alt=""
                    className="egox_token_div_section2_area_body_cont1_icon_div_img"
                  />
                </div>
                <div className="egox_token_div_section2_area_body_cont1_title">
                  Transaction Fee Utility
                </div>
                <div className="egox_token_div_section2_area_body_cont1_para">
                  XIFY fuels all transactions within the Egoxify platform,
                  including borrowing, lending, and governance activities. This
                  ensures a seamless and efficient transactional experience
                  while providing utility to the token.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      <section className="egox_token_div_section3">
        <div className="container">
          <div className="egox_token_div_section3_area">
            <div className="egox_token_div_section3_area_title">
              Token Distribution
              <div className="egox_token_div_section3_area_title_para">
                A total of 280 million XIFY tokens have been allocated to
                various stakeholders, ensuring a robust and sustainable
                ecosystem
              </div>
            </div>
            <div className="egox_token_div_section3_area_body">
              <img
                src="/img/token_distru_chart.png"
                alt=""
                className="egox_token_div_section3_area_body_img"
              />
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      {/* ========================= */}
      <section className="egox_token_div_section3">
        <div className="container">
          <div className="egox_token_div_section3_area">
            <div className="egox_token_div_section3_area_title">
              Road Map
              <div className="egox_token_div_section3_area_title_para">
                Token Roadmap
              </div>
            </div>
            <div className="egox_token_div_section3_area_body">
              <Roadmap />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Egox;
