import React, { useEffect, useState } from "react";

import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Egox from "./components/Home/Egox";
import Home from "./components/Home/Home";
import { Route, Routes, Navigate } from "react-router-dom";
import CexRoutes from "./components/Exchange/Cex/Routes";
import CexHome from "./components/Exchange/Cex/pages/CexHome";
import Stake from "./components/Exchange/Dex/Staking/Stake";
import RouteConfig from "./components/Exchange/Cex/Route.config";
import Dex from "./components/Exchange/Dex/Dex";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Privacy from "./components/PrivacyPolicy/Privacy";
import TermsAndConditions from "./components/PrivacyPolicy/TermsAndConditions";
import "./stylesheet/darkMode.css";
function App() {
  const [darkMode, setDarkMode] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("uiMode") === "light") {
      setDarkMode(false);
    } else {
      setDarkMode(true);
    }
  });

  const togglemakeDarkUi = () => {
    if (localStorage.getItem("uiMode") === "light") {
      localStorage.setItem("uiMode", "dark");
      setDarkMode(true);
    } else {
      localStorage.setItem("uiMode", "light");
      setDarkMode(false);
    }
    console.log(localStorage.getItem("uiMode"));
  };
  function getLibrary(provider) {
    return new Web3Provider(provider);
  }

  if (window.location.host.split(".")[0] === "cex") {
    return (
      <div className={darkMode === true ? "dark " : ""}>
        <RouteConfig darkMode={darkMode} togglemakeDarkUi={togglemakeDarkUi} />
      </div>
    );
  }

  if (window.location.host.split(".")[0] === "dex") {
    return (
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className={darkMode === true ? "dark " : ""}>
          <Dex darkMode={darkMode} togglemakeDarkUi={togglemakeDarkUi} />
        </div>
      </Web3ReactProvider>
    );
  }

  return (
    <div className={darkMode === true ? "dark " : "App"}>
      <Header darkMode={darkMode} togglemakeDark={togglemakeDarkUi} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/goxify/token" element={<Egox />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
