import React from "react";

import "./privacy.css";
import "./privacyMobile.css";
const TermsAndConditions = () => {
  return (
    <div>
      {/* hero section start */}
      <section className="heroSection4">
        <div className="container">
          <div className="heroArea4">
            <div className="heroTxts4">
              <h1
                className="heroTitle4"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-once="false"
              >
                Terms & Conditions
              </h1>
              <p
                className="hero4para"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-once="false"
              >
                NEED SUPPORT:{" "}
                <a href="mailto:support@egoxify.com" className="egrEmailLink">
                  support@egoxify.com
                </a>
              </p>
            </div>

            <div className="hero-images4" data-aos="fade-left">
              <img src="/img/privacy-image.svg" alt="" className="privacy" />
            </div>
          </div>
          <div className="privacyTextList">
            <span
              className="privacyTextList_span
            "
            >
              Last Date Updated 15 December 2023.
            </span>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName">Introduction:</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                This website provides general information about the services we
                offer and facilitate. Accessing specific offerings, like token
                purchases or pool investments, requires completing customer
                onboarding and other requirements.
              </p>
            </div>

            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName">
                    Acceptance of Terms and Conditions
                  </span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                By accessing this website, you acknowledge and agree to be bound
                by these Terms of Use, including any updated policies
                incorporated by reference. You must accept and comply with these
                Terms and applicable laws to use this website. If you disagree,
                please do not proceed.
              </p>
            </div>

            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle " data-aos="fade-right">
                  <span className="titleName">Geographic Restrictions</span>
                </h1>
              </div>

              <p className="AboutUsPara" data-aos="fade-right">
                Accessing this website may be prohibited or restricted due to
                legal or regulatory limitations in certain jurisdictions. Please
                ensure you are authorized to access this website before
                proceeding.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName">Not an Investment Offer</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Information on this website is for informational purposes only
                and does not constitute an offer, solicitation, or invitation to
                buy, sell, or subscribe to any securities, derivatives,
                investments, or financial instruments. We do not offer consumer
                credit services, accept deposits, or provide any other financial
                services in any jurisdiction.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName"> Service Availability</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Some services described may not be available in your
                jurisdiction or may be limited to certain types of investors.
                Signing up for free or subscription-based content does not
                guarantee access to other services.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName"> Content Use</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                The materials on this website are for your personal,
                non-commercial use and are not intended for distribution or
                publication in jurisdictions where their use would be contrary
                to local laws or subject us to licensing requirements.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName"> Regulatory Disclaimer</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Virtual assets and pools are high-risk, unregulated products.
                Services related to them may be subject to legal or regulatory
                restrictions in various jurisdictions. Failing to comply can
                result in personal liability or fines. Check your local
                regulations and seek professional advice if unsure.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName"> Investment Disclaimer</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Neither Egoxify nor its affiliates warrant or represent the
                suitability of virtual assets or pools as investments or their
                availability and liquidity in your jurisdiction. Consult your
                professional advisor before investing and acknowledge you do so
                at your own risk.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName">
                    {" "}
                    Not an Investment Recommendation
                  </span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                The materials on this website are not advertisements,
                recommendations, solicitations, or offers to buy, sell, or
                subscribe to any virtual assets or specific pools. Market
                information provided may change and is not guaranteed to be
                accurate or complete.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName">
                    {" "}
                    No Market Information Endorsement
                  </span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Egoxify and its affiliates bear no responsibility for, control
                over, or endorsement of any market information published within
                the materials. Such information is attributed to its source and
                is for informational purposes only.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName"> Price Disclaimer</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                Any price or level information provided is indicative only and
                subject to change at any time. Always access the most current
                information before making any decisions.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName"> Limitation of Liability</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                To the extent permitted by law, Egoxify and its affiliates,
                agents, advisors, directors, officers, employees, and
                shareholders shall not be liable for any damages (direct or
                indirect) arising from the use of this website, its materials,
                or the services described therein.
              </p>
            </div>
            <div className="privacyTxts">
              <div className="privacyLine" data-aos="fade-right"></div>
              <div className="loanDetailsTitle">
                <h1 className="PrivacyTitle" data-aos="fade-right">
                  <span className="titleName">Contact</span>
                </h1>
              </div>
              <p className="AboutUsPara" data-aos="fade-right">
                If you have any questions about these Terms of Use, please
                contact us at{" "}
                <a href="mailto:support@egoxify.com" className="egrEmailLink">
                  support@egoxify.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>

        <img src="/img/projects-blurbg.png" alt="" className="privacyBlurBg" />
        <img
          src="/img/privacy-rightbg.png"
          alt=""
          className="privacyrightBlurBg"
        />
      </section>
      {/* hero section end */}
      {/* =================================================================================================================================================================================================================================================================== */}
    </div>
  );
};

export default TermsAndConditions;
