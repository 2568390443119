import { Contract } from "@ethersproject/contracts";
import V3ContractAddress from "./contracts/V3ContractAddress.json";
import PancakeSwapFaucet from "./contracts/PancakeSwapFacet.json";
import erc20 from "./contracts/erc20.json";
import { formattedError } from "./FormattedError";
import StakingFacet from "./contracts/StakingFacet.json";
import PriceOracleFacet from "./contracts/PriceOracleFacet.json";
const { REACT_APP_PANCAKE_ROUTER_ADDRESS, REACT_APP_BUSD_ROUTER_ADDRESS } =
  process.env;

const routerAddressArray = [
  REACT_APP_PANCAKE_ROUTER_ADDRESS,
  REACT_APP_BUSD_ROUTER_ADDRESS,
];
const contractStakingFacetInstance = (signer) => {
  return new Contract(V3ContractAddress.address, StakingFacet.abi, signer);
};
const contractPriceOracleFacetInstance = async (signer) => {
  return new Contract(V3ContractAddress.address, PriceOracleFacet.abi, signer);
};
const contractPancakeSwapFacetInstance = async (signer) => {
  return new Contract(V3ContractAddress.address, PancakeSwapFaucet.abi, signer);
};
const erc20Instance = (address, signer) => {
  return new Contract(address, erc20.abi, signer);
};

const unlockTokenV3 = async (coinAddress, amount, signer) => {
  try {
    const instance = erc20Instance(coinAddress, signer);
    let result = await instance.approve(V3ContractAddress.address, amount);
    return {
      message: result.hash,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const checkAllowanceV3 = async (coinAddress, owner, amount, signer) => {
  try {
    const instance = erc20Instance(coinAddress, signer);
    let result = await instance.allowance(owner, V3ContractAddress.address);

    if (parseFloat(result.toString()) >= parseFloat(amount.toString())) {
      return {
        status: true,
      };
    } else {
      return {
        status: false,
      };
    }
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const takeRoyalty = async (signer) => {
  // console.log(amount);
  try {
    const instance = await contractStakingFacetInstance(signer);
    let result;
    result = await instance.takeRoyaltyStable();
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};
const tokenBalance = async (address, account, signer) => {
  try {
    const instance = erc20Instance(address, signer);
    let result = await instance.balanceOf(account);

    return {
      message: result,
      status: true,
    };
  } catch (error) {
    console.log(error);
    return {
      message: error,
      status: false,
    };
  }
};
const setStakeConfig = async (_eusdAddr, _yearlyInterest, signer) => {
  console.log(_eusdAddr, _yearlyInterest);
  try {
    const instance = await contractStakingFacetInstance(signer);
    let result;
    result = await instance.setStakeConfigStable(_eusdAddr, _yearlyInterest);
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};
const monthly = async (amount, signer) => {
  console.log(amount);
  try {
    const instance = await contractStakingFacetInstance(signer);
    let result;
    result = await instance.monthlyStable(amount);
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const annually = async (amount, signer) => {
  console.log(amount);
  try {
    const instance = await contractStakingFacetInstance(signer);
    let result;
    result = await instance.annuallyStable(amount);
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const getEGCEUSDTICKERPRICE = async (_ticker, signer) => {
  try {
    const instance = await contractPriceOracleFacetInstance(signer);
    let result = await instance.price(_ticker);
    console.log(result.toString(), "result");

    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const getRoyaltyStats = async (user, signer) => {
  try {
    const instance = await contractStakingFacetInstance(signer);
    let result = await instance.royaltyStatsStable(user);
    console.log(result.toString(), "result");

    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const stakeConfig = async (signer) => {
  try {
    const instance = await contractStakingFacetInstance(signer);
    let result = await instance.stakeConfigStable();
    console.log(result, "result");

    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};
const getCalculatedRoyalty = async (user, signer) => {
  console.log(user, signer, "getcalculatedRoyalty");
  try {
    const instance = await contractStakingFacetInstance(signer);
    let result = await instance.calculateRoyaltyStable(user);
    console.log(result, "result");

    return {
      message: result,
      status: true,
    };
  } catch (error) {
    console.log("====================================");
    console.log(error);
    console.log("====================================");
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const UnlockLockedStake = async (signer) => {
  try {
    const instance = await contractStakingFacetInstance(signer);
    let result = await instance.unstakeStable();
    console.log(result, "result");

    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const getBNBAddress = async (signer) => {
  try {
    const instance = await contractPancakeSwapFacetInstance(signer);
    let result;
    result = await instance.getWethAddress();
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};
const swapEusdForBnb = async (token, amountIn, amountOutMin, signer) => {
  try {
    const instance = await contractPancakeSwapFacetInstance(signer);
    let result;
    result = await instance.swapExactEUSDforBNB(
      token,
      amountIn,
      amountOutMin,
      routerAddressArray
    );
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};
const swapBnbForEusd = async (value, amountOutMin, tokenOut, signer) => {
  try {
    const instance = await contractPancakeSwapFacetInstance(signer);
    let result;
    result = await instance.swapExactBNBForEUSD(
      amountOutMin,
      tokenOut,
      routerAddressArray,
      {
        value,
      }
    );

    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const swapEusdForToken = async (amountIn, amountOutMin, path, signer) => {
  console.log(
    amountIn,
    amountOutMin,
    path,
    routerAddressArray,
    "swapEusdForToken index.js"
  );
  try {
    const instance = await contractPancakeSwapFacetInstance(signer);
    let result;
    result = await instance.swapExactEUSDForTokens(
      amountIn,
      amountOutMin,
      path,
      routerAddressArray
    );
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

const swapTokenForEusd = async (amountIn, amountOutMin, path, signer) => {
  console.log(
    amountIn,
    amountOutMin,
    path,
    routerAddressArray,
    "SwapTokensForEusd index.js"
  );
  try {
    const instance = await contractPancakeSwapFacetInstance(signer);
    let result;
    result = await instance.swapExactTokensForEUSD(
      amountIn,
      amountOutMin,
      path,
      routerAddressArray
    );
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};
const getAmountsOut = async (amountIn, path, signer) => {
  console.log(amountIn, path, signer, "get amount out");
  try {
    const instance = await contractPancakeSwapFacetInstance(signer);
    let result;
    result = await instance.getAmountsOut(amountIn, path, routerAddressArray);
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};
const getAmountsIn = async (amountOut, path, signer) => {
  console.log(amountOut, path);
  try {
    const instance = await contractPancakeSwapFacetInstance(signer);
    let result;
    result = await instance.getAmountsIn(amountOut, path, routerAddressArray);
    console.log(result, "result, result,result,result,result");
    return {
      message: result,
      status: true,
    };
  } catch (error) {
    return {
      message: formattedError(error).message,
      status: formattedError(error).status,
    };
  }
};

export {
  unlockTokenV3,
  checkAllowanceV3,
  takeRoyalty,
  tokenBalance,
  monthly,
  annually,
  getEGCEUSDTICKERPRICE,
  getRoyaltyStats,
  stakeConfig,
  getCalculatedRoyalty,
  UnlockLockedStake,
  getBNBAddress,
  swapEusdForBnb,
  swapBnbForEusd,
  swapEusdForToken,
  swapTokenForEusd,
  getAmountsOut,
  getAmountsIn,
  setStakeConfig,
};
