import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Header from "../../Header/Header";
import DefiHeader from "../../Header/DefiHeader";
import Footer from "../../Footer/Footer";
import Stake from "./Staking/Stake";
import UpdatedSwap from "./UpdatedSwap/UpdatedSwap";
import Function from "./Function";

const Dex = ({ darkMode, togglemakeDarkUi }) => {
  const context = useWeb3React();
  const { account } = context;
  const [promptDiv, setPropmtDiv] = useState(false);
  const currentPage = window.location.pathname;
  const urlArr = currentPage.split("/");

  useEffect(() => {
    if (account) {
      localStorage.setItem("myName", account);
      localStorage.setItem("WA_ST", account);
      console.log(account);
      console.log(localStorage.getItem("myName"), "myName");
      console.log(localStorage.getItem("WA_ST"), "wa_st");
    }
  }, [account]);

  return (
    <>
      <DefiHeader darkMode={darkMode} togglemakeDark={togglemakeDarkUi} />
      <Routes>
        <Route path="/" element={<UpdatedSwap />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/function" element={<Function />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Dex;
