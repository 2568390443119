import React, { useState, useEffect } from "react";
import "../../stylesheet/footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { routeHelper } from "../../helper";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update the current year when the component mounts
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000); // Update the year every second (you can adjust the interval as needed)

    // Clear the interval when the component unmounts    sss
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="footerDiv" id="footer">
      <div className="container">
        <div className="footerDivArea">
          <div className="footerDiv1">
            <div className="footerDiv1_area1">
              <div className="footerDiv1_area1_cont1">
                <img src="/egox1.png" alt="" className="footerDiv1_img2" />
                Egoxify
              </div>
              <div className="footerDiv1_area1_cont2">
                <div className="footerDiv1_area1_cont2_links">
                  <a
                    href="https://twitter.com/egoxify"
                    className="footerDiv1_area1_cont2_links_link_tel"
                    target="_blank"
                  >
                    <TwitterIcon className="footerDiv1_area1_cont2_links_link1" />{" "}
                    <span className="footerDiv1_area1_cont2_links_link_tel_span">
                      Twitter
                    </span>
                  </a>
                  <a
                    href="https://egoxify.medium.com/"
                    className="footerDiv1_area1_cont2_links_link_tel"
                    target="_blank"
                  >
                    <img
                      src="/img/medium_icon.svg"
                      alt=""
                      className="footerDiv1_area1_cont2_links_link_img"
                    />
                    <span className="footerDiv1_area1_cont2_links_link_tel_span">
                      Medium
                    </span>
                  </a>
                  <a
                    href="https://t.me/goxify"
                    className="footerDiv1_area1_cont2_links_link_tel"
                    target="_blank"
                  >
                    <TelegramIcon className="footerDiv1_area1_cont2_links_link1" />{" "}
                    <span className="footerDiv1_area1_cont2_links_link_tel_span">
                      Community
                    </span>
                  </a>
                  <a
                    href="https://t.me/goxify_news"
                    className="footerDiv1_area1_cont2_links_link_tel"
                    target="_blank"
                  >
                    <TelegramIcon className="footerDiv1_area1_cont2_links_link1" />{" "}
                    <span className="footerDiv1_area1_cont2_links_link_tel_span">
                      Channel
                    </span>
                  </a>
                  <a
                    href="https://www.youtube.com/@goxifyHQ"
                    className="footerDiv1_area1_cont2_links_link_tel"
                    target="_blank"
                  >
                    <YouTubeIcon className="footerDiv1_area1_cont2_links_link1" />{" "}
                    <span className="footerDiv1_area1_cont2_links_link_tel_span">
                      Youtube
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="footerDiv1_area2">
              <div className="footerDiv1_area2_cont1">
                <div className="footerDiv1_area2_title">Protocol</div>
                <div className="footerDiv1_area2_title_subLinks_div">
                  <a
                    href={
                      window.location.protocol === "http:"
                        ? `http://localhost:${window.location.port}/privacy`
                        : `https://egox.io/privacy`
                    }
                    className="footerDiv1_area2_title_subLinks_div_link1"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href={
                      window.location.protocol === "http:"
                        ? `http://localhost:${window.location.port}/TermsAndConditions`
                        : `https://egox.io/TermsAndConditions`
                    }
                    className="footerDiv1_area2_title_subLinks_div_link1"
                  >
                    Terms & conditions
                  </a>
                  <a
                    href="/goxify/token"
                    className="footerDiv1_area2_title_subLinks_div_link1"
                  >
                    Token
                  </a>
                  <a
                    href="https://docs.egox.io"
                    className="footerDiv1_area2_title_subLinks_div_link1"
                    target="_blank"
                  >
                    Documentation
                  </a>
                  {/* <a
                    href="/about"
                    className="footerDiv1_area2_title_subLinks_div_link1"
                  >
                    About Us
                  </a> */}
                </div>
              </div>
              <div className="footerDiv1_area2_cont1">
                <div className="footerDiv1_area2_title">Need Help?</div>
                <div className="footerDiv1_area2_title_subLinks_div">
                  <a
                    href="mailto:support@egoxify.com"
                    className="footerDiv1_area2_title_subLinks_div_link1"
                    target="_blank"
                  >
                    support@egoxify.com
                  </a>
                </div>
              </div>
            </div>
          </div>

          <hr className="footer_hr" />
          <div className="footer_lastDiv">© {currentYear} Egoxify.com</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
