import React from "react";
import "./roadmap.css";

const Roadmap = () => {
  return (
    <div className="roadMapDiv">
      {" "}
      <section className="roadmap">
        <div className="section-timeline-4">
          <div className="containercool-2">
            <div className="timeline_wrappercool-2">
              <div className="timeline_progresscool">
                <div className="timeline_progress-barcool"></div>
              </div>
              <div
                data-w-id="046baf0c-9b4c-ea28-05a2-50fd0f70aa61"
                className="timeline_item-2"
              >
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aa62-718776cd"
                  className="timeline_left"
                >
                  <div className="timeline_date-text">Q3 2023</div>
                </div>
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aa65-718776cd"
                  className="timeline_centre"
                >
                  <div className="timeline_circle"></div>
                </div>
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aa67-718776cd"
                  className="timeline_right"
                >
                  <div className="margin-bottom-xlarge">
                    <div className="timeline_text-2">
                      <h2>Building the Foundation</h2>
                      <br />
                      <b>Protocol Development:</b> Design and implement the core
                      infrastructure of the Egoxify protocol, enabling
                      peer-to-peer vehicle financing and ownership.
                      <br />
                      <br />
                      <b>Manufacturer Onboarding:</b> Secure partnerships with
                      prominent automobile manufacturers to integrate Egoxify
                      into their ecosystems.
                      <br />
                      <br />
                      <b> Testnet Launch:</b>
                      Deploy a functional testnet version of the platform for
                      community testing and feedback.
                      <br />
                      <br />
                      <b>Continued Onboarding:</b> Expand manufacturer
                      partnerships to increase platform adoption and vehicle
                      availability.
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-w-id="046baf0c-9b4c-ea28-05a2-50fd0f70aa89"
                className="timeline_item-2"
              >
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aa8a-718776cd"
                  className="timeline_left"
                >
                  <div className="timeline_date-text">Q4 2023</div>
                </div>
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aa8d-718776cd"
                  className="timeline_centre"
                >
                  <div className="timeline_circle"></div>
                </div>
                <div className="timeline_right">
                  <div className="margin-bottom-medium-2">
                    <div className="timeline_text-2">
                      <h2>Mainnet Launch and Expansion</h2>
                      <br />
                      <b>Avalanche Mainnet Launch:</b> Deploy the Egoxify
                      protocol on the Avalanche mainnet, marking a significant
                      step towards public accessibility.
                      <br />
                      <br />
                      <b>Multichain Deployment: </b> Extend reach to additional
                      blockchains like Celo, Fathom, Klaytn, and Polygon,
                      fostering cross-chain interoperability.
                      <br />
                      <br />
                      <b> Community Engagement: </b>
                      Launch a community drop campaign across Twitter, Telegram,
                      and Medium to incentivize early participation.
                      <br />
                      <br />
                      <b>Token Distribution: </b>Release the Egoxify token on
                      supported blockchains, facilitating platform access and
                      governance.
                      <br />
                      <br />
                      <b>Private Token Sale: </b>Initiate a private token sale
                      round to attract initial investment and fuel further
                      development.
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="046baf0c-9b4c-ea28-05a2-50fd0f70aa97"
                className="timeline_item-2"
              >
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aa98-718776cd"
                  className="timeline_left"
                >
                  <div className="timeline_date-text">Q1 2024</div>
                </div>
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aa9b-718776cd"
                  className="timeline_centre"
                >
                  <div className="timeline_circle"></div>
                </div>
                <div className="timeline_right">
                  <div className="margin-bottom-medium-2">
                    <div className="timeline_text-2">
                      <h2>Growth and Optimization</h2>
                      <br />
                      <b>Public Token Sale: </b> Conduct a public token sale,
                      making Egoxify accessible to a broader investor base.
                      <br />
                      <br />
                      <b>Security and Trust: </b> Secure an audit from a
                      reputable firm like Certik to build user trust and
                      platform security.
                      <br />
                      <br />
                      <b> Exchange Listings: </b>
                      Secure listings on Tier 2 cryptocurrency exchanges,
                      enhancing liquidity and accessibility.
                      <br />
                      <br />
                      <b>Manufacturer Expansion: </b>Onboard additional
                      automobile manufacturers to diversify vehicle options and
                      increase market share.
                      <br />
                      <br />
                      <b>eUSD Savings Campaign: </b>Launch a savings campaign
                      incentivizing users to deposit eUSD, the stablecoin
                      integrated within the Egoxify protocol.
                      <br />
                      <br />
                      <b>Governance Platform Launch: </b>Introduce a
                      decentralized governance platform, empowering token
                      holders to shape the future of Egoxify.
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="046baf0c-9b4c-ea28-05a2-50fd0f70aaa4"
                className="timeline_item-2"
              >
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aaa5-718776cd"
                  className="timeline_left"
                >
                  <div className="timeline_date-text">Q2 2024</div>
                </div>
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aaa8-718776cd"
                  className="timeline_centre"
                >
                  <div className="timeline_circle"></div>
                </div>
                <div className="timeline_right">
                  <div className="margin-bottom-medium-2">
                    <div className="margin-bottom-medium-2">
                      <div className="timeline_text-2">
                        <h2>Scaling and Sustainability</h2>
                        <br />
                        <b>Tier 2 Exchange Expansion:</b>Secure listings on
                        additional Tier 2 exchanges, further boosting visibility
                        and trading volume.
                        <br />
                        <br />
                        <b>Manufacturer Partnerships: </b> Continue onboarding
                        established and emerging automobile manufacturers to
                        fuel platform growth.
                        <br />
                        <br />
                        <b> Reaching Milestones: </b>
                        Achieve a $30 million USD target in the eUSD savings
                        pool, demonstrating user trust and platform utility.
                        <br />
                        <br />
                        <b>200,000 Users: </b>Aim to reach 200,000 active users
                        on the Egoxify platform, solidifying its position as a
                        leading player in decentralized vehicle ownership.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="046baf0c-9b4c-ea28-05a2-50fd0f70aab5"
                className="timeline_item-2"
              >
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aab6-718776cd"
                  className="timeline_left"
                >
                  <div className="timeline_date-text">Q3 2024</div>
                </div>
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aab9-718776cd"
                  className="timeline_centre"
                >
                  <div className="timeline_circle"></div>
                </div>
                <div className="timeline_right">
                  <div className="margin-bottom-medium-2">
                    <div className="timeline_text-2">
                      <h2>Dominating the Decentralized Future</h2>
                      <br />
                      <b>Social Media Reach: </b>Grow social media followers to
                      1.5 million across platforms, building a strong and
                      engaged community.
                      <br />
                      <br />
                      <b>Financial Growth: </b> Increase the eUSD savings pool
                      to $80 million USD, highlighting the platform’s economic
                      strength and user interest.
                      <br />
                      <br />
                      <b>Tier 2 Expansion: </b>
                      Secure listings on additional Tier 2 exchanges to further
                      amplify user accessibility and trading opportunities.
                      <br />
                      <br />
                      <b>Debt Position Advancement: </b>Reach a $70 million USD
                      debt position, showcasing the platform’s ability to
                      facilitate large-scale vehicle financing.
                      <br />
                      <br />
                      <b>Continued Onboarding:</b>Expand partnerships with
                      manufacturers, diversifying options and solidifying
                      Egoxify’s market dominance.
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="046baf0c-9b4c-ea28-05a2-50fd0f70aac5"
                className="timeline_item-2"
              >
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aac6-718776cd"
                  className="timeline_left"
                >
                  <div className="timeline_date-text">Q4 2024</div>
                </div>
                <div
                  id="w-node-_046baf0c-9b4c-ea28-05a2-50fd0f70aac9-718776cd"
                  className="timeline_centre"
                >
                  <div className="timeline_circle"></div>
                </div>
                <div className="timeline_right">
                  <div className="margin-bottom-medium-2">
                    <div className="timeline_text-2">
                      <h2>Beyond the Horizon</h2>
                      <br />
                      <b>New Roadmap: </b>Unveiling a new roadmap outlining the
                      next phase of growth and innovation for the Egoxify
                      ecosystem.
                      <br />
                      <br />
                      <b>Tier 1 Exchange Launch: </b> Secure listings on Tier 1
                      cryptocurrency exchanges, solidifying Egoxify’s position
                      as a top player in the industry.
                      <br />
                      <br />
                      <b>1 Million Users: </b>
                      Reach a critical milestone of 1 million active users on
                      the Egoxify platform, cementing its status as a mainstream
                      solution for decentralized vehicle ownership.
                      <br />
                      <br />
                      <b>$100 Million USD Savings Pool: </b>Achieve a $100
                      million USD target in the eUSD savings pool, demonstrating
                      the platform’s immense financial potential.
                      <br />
                      <br />
                      <b>2 Million Social Media Followers:</b>Build a loyal and
                      engaged community of 2 million followers across social
                      media platforms, solidifying Egoxify’s brand presence.
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlay-fade-bottom-2"></div>
              <div className="overlay-fade-top-2"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Roadmap;
