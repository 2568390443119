import React, { useState, useEffect } from "react";
import "../../stylesheet/header.css";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import { Authenticate } from "../auth/Authenticate";
import { useWeb3React } from "@web3-react/core";
import { parseEther, formatEther } from "@ethersproject/units";
import ConnectInjected from "../ConnectInjected";
import ConnectWalletConnect from "../ConnectWalletConnect";
import { tokenBalance } from "../../web3";
import { routeHelper } from "../../helper";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Web3 from "web3";
const { REACT_APP_EGC_ADDRESS, REACT_APP_EUSD_ADDRESS } = process.env;

const DefiHeader = ({ darkMode, togglemakeDark }) => {
  const context = useWeb3React();
  const { connector, account, library } = context;
  const [lightMode, setLightMode] = useState(true);
  const [tradeDrop, setTradeDrop] = useState(false);
  const [coinBalance, setCoinBalance] = React.useState("0.00");
  const [coinBalance2, setCoinBalance2] = React.useState("0.00");
  const [headerMenu, setHeaderMenu] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const ToggleLightMode = () => {
    setLightMode(!lightMode);
  };
  const ToggleTradeDrop = () => {
    setTradeDrop(!tradeDrop);
  };

  const ToggleHeaderMenu = () => {
    setHeaderMenu(!headerMenu);
  };
  const ToggleSettingsMenu = () => {
    setSettingsMenu(!settingsMenu);
  };

  const web3 = new Web3(window.ethereum);
  const getMetamaskWalletBal = async () => {
    const getBalance = await web3.eth.getBalance(account);
    const ethBalance = web3.utils.fromWei(getBalance, "ether");
    console.log(ethBalance);
    setCoinBalance(parseFloat(ethBalance).toFixed(4));
  };
  useEffect(() => {
    if (account) {
      getMetamaskWalletBal();
    }
  }, [coinBalance, account]);

  const getTokenBalance = async () => {
    let res = await tokenBalance(
      REACT_APP_EUSD_ADDRESS,
      account,
      library.getSigner()
    );
    console.log(res);
    console.log(formatEther(res.message._hex));
    setCoinBalance2(parseFloat(formatEther(res.message._hex)).toFixed(2));
  };

  useEffect(() => {
    if (account) {
      getTokenBalance();
    }
  }, [account]);

  return (
    <div className="header_div">
      <div className="container">
        <div className="header_div_area">
          <div className="header_div_area_cont1">
            <img
              src="/egox1.png"
              alt=""
              className="header_div_area_cont1_img"
            />
            Egoxify
            {/* <span className="header_div_area_cont1_span">x</span> */}
            <div className="header_div_area_cont2">
              <a href="/" className="header_div_area_cont2_link1">
                Trade
              </a>
              <a href="/stake" className="header_div_area_cont2_link1">
                Earn
              </a>
              <a href="#" className="header_div_area_cont2_link1">
                Borrow
              </a>
              <a href="/goxify/token" className="header_div_area_cont2_link1">
                Token
              </a>
              <a
                href="https://docs.egox.io"
                className="header_div_area_cont2_link1"
                target="_blank"
              >
                Docs
              </a>
            </div>
          </div>

          <div className="header_div_area_cont3">
            {account ? null : (
              <div className="mobile_wall_connct_div">
                <Authenticate isHome="false" />
              </div>
            )}

            <MenuIcon
              className="header_div_area_cont3_icon"
              onClick={ToggleHeaderMenu}
            />
            <div className="header_div_area_cont3_div3">
              {account ? (
                <div className="header_div_area_cont3_div3_area">
                  <div className="header_div_area_cont3_div3_area_toggle_div">
                    <div className="header_div_area_cont3_div3_area_toggle_div_txt">
                      <img
                        src="/img/bnb_chain.svg"
                        alt=""
                        className="header_div_area_cont3_div3_area_toggle_div_txt_img"
                      />
                      BNB Chain
                    </div>
                    <KeyboardArrowDownIcon className="header_div_area_cont2_link1_icon" />
                  </div>
                  <div className="header_div_area_cont3_div3_area_eusd_bal_div">
                    <img
                      src="/egox1.png"
                      alt=""
                      className="header_div_area_cont3_div3_area_eusd_bal_div_img"
                    />
                    {coinBalance2}
                  </div>
                  <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                      <img
                        src="/img/metamask_icon.svg"
                        alt=""
                        className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_img"
                      />
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                        {coinBalance} BNB
                      </div>
                    </div>
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                      {`${account.slice(0, 4)}...${account.slice(20, 24)}`}
                    </div>
                  </div>
                </div>
              ) : (
                <Authenticate isHome="false" />
              )}

              {/* <ConnectInjected />
              <ConnectWalletConnect /> */}
            </div>

            <div
              className="header_div_area_cont3_div2"
              onClick={ToggleSettingsMenu}
            >
              <SettingsIcon className="header_div_area_cont3_div2_icon" />
              {settingsMenu ? (
                <div className="header_settings_div">
                  <div className="header_settings_div_cont_1">
                    <span className="header_settings_div_cont_1_span1">
                      UI-mode
                    </span>
                    <span className="header_settings_div_cont_1_span2">
                      {darkMode ? (
                        <Brightness7Icon
                          className="header_div_area_cont3_div2_icon"
                          onClick={togglemakeDark}
                        />
                      ) : (
                        <NightsStayIcon
                          className="header_div_area_cont3_div2_icon"
                          onClick={togglemakeDark}
                        />
                      )}
                    </span>
                  </div>
                  <div className="header_settings_div_cont_1">
                    <span className="header_settings_div_cont_1_span1">
                      Language
                    </span>
                    <span className="header_settings_div_cont_1_span2">
                      <img
                        src="/img/language_select_img1.svg"
                        alt=""
                        className="header_div_area_cont3_img"
                      />
                    </span>
                  </div>
                  <div className="header_settings_div_cont_btn">
                    <Authenticate />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {headerMenu ? (
        <div className="headerMenuDiv">
          <div className="headerMenuDiv_cont">
            <CloseIcon
              className="header_div_area_cont3_icon2"
              onClick={ToggleHeaderMenu}
            />
            <a href="/" className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Trade</span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <a href="/stake" className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Earn</span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <a href="#" className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Borrow</span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <a href="/goxify/token" className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Token</span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <a
              href="https://docs.egox.io"
              target="_blank"
              className="headerMenuDiv_cont_1"
            >
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Docs</span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Chain</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {account ? (
                  <div className="header_div_area_cont3_div3_area_toggle_div">
                    <div className="header_div_area_cont3_div3_area_toggle_div_txt">
                      <img
                        src="/img/bnb_chain.svg"
                        alt=""
                        className="header_div_area_cont3_div3_area_toggle_div_txt_img"
                      />
                      BNB Chain
                    </div>
                    <KeyboardArrowDownIcon className="header_div_area_cont2_link1_icon" />
                  </div>
                ) : (
                  <div className="header_div_area_cont3_div3_area_toggle_div">
                    <div className="header_div_area_cont3_div3_area_toggle_div_txt">
                      <img
                        src="/img/bnb_chain.svg"
                        alt=""
                        className="header_div_area_cont3_div3_area_toggle_div_txt_img"
                      />
                      BNB Chain
                    </div>
                    <KeyboardArrowDownIcon className="header_div_area_cont2_link1_icon" />
                  </div>
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Eusd Bal</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {account ? (
                  <div className="header_div_area_cont3_div3_area_eusd_bal_div">
                    <img
                      src="/egox1.png"
                      alt=""
                      className="header_div_area_cont3_div3_area_eusd_bal_div_img"
                    />
                    {coinBalance2}
                  </div>
                ) : (
                  <div className="header_div_area_cont3_div3_area_eusd_bal_div">
                    <img
                      src="/egox1.png"
                      alt=""
                      className="header_div_area_cont3_div3_area_eusd_bal_div_img"
                    />
                    0.00
                  </div>
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Wallet Bal</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {account ? (
                  <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                      <img
                        src="/img/metamask_icon.svg"
                        alt=""
                        className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_img"
                      />
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                        {coinBalance} BNB
                      </div>
                    </div>
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                      {`${account.slice(0, 4)}...${account.slice(20, 24)}`}
                    </div>
                  </div>
                ) : (
                  <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                      <img
                        src="/img/metamask_icon.svg"
                        alt=""
                        className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_img"
                      />
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                        0.00 BNB
                      </div>
                    </div>
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                      0xXX...XXX
                    </div>
                  </div>
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">UI-mode</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {darkMode ? (
                  <Brightness7Icon
                    className="header_div_area_cont3_div2_icon"
                    onClick={togglemakeDark}
                  />
                ) : (
                  <NightsStayIcon
                    className="header_div_area_cont3_div2_icon"
                    onClick={togglemakeDark}
                  />
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Language</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                <img
                  src="/img/language_select_img1.svg"
                  alt=""
                  className="header_div_area_cont3_img"
                />
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DefiHeader;
